import { Rowing } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import BomFormula from "./BomFormula";

function Screen10({ formik, isPageComplete }) {

  const installedCapacity = parseFloat(formik.values.installedCapacity) || 0;
  const totalBank = parseInt(formik.values.bank) || 0;
  const effectiveVessel = parseInt(formik.values.multistackvessel) || 1;
  const bundleHeatLoad = installedCapacity / effectiveVessel;

  const handleInputChange = (e, rowIndex, colIndex) => {
    const { value } = e.target;
    const updatedTableData = [...formik.values.bundleTableData];
    updatedTableData[rowIndex].bundle[colIndex].bank = value;

    const total =
      bundleHeatLoad - updatedTableData[rowIndex].bundle[colIndex].bank;
    const bank = formik.values.bank - 1;
    const eachData = total / bank;
    updatedTableData[rowIndex].bundle.map((data, index) => {
      if (index !== colIndex) {
        data.bank = eachData;
      }
    });

    formik.setFieldValue("bundleTableData", updatedTableData);
  };

  const ipRatingOptions = [
    { value: "IP-42", label: "IP-42" },
    { value: "IP-54", label: "IP-54" },
    { value: "IP-55", label: "IP-55" },
    { value: "IP-65", label: "IP-65" },
    { value: "IP-66", label: "IP-66" },
    { value: "IP-67", label: "IP-67" },
    { value: "IP-68", label: "IP-05" },
  ]

  console.log(formik.values);
  console.log(formik.values.cp_cableEntry);
  
  

  return (
    <div>
      <div
        className="w-100 px-4 row justify-content-center mb-30"
        style={{ gap: "20px" }}
      >
        <label htmlFor="" style={{ fontSize: "20px", fontWeight: "bold" }}>
          Control Panel
        </label>
        <div className="d-flex align-items-center" style={{ gap: "20px" }}>
          <input
            type="radio"
            id="cp_controlPanel"
            name="cp_controlPanel"
            checked={formik?.values?.cp_controlPanel === "yes"}
            onChange={() => formik.setFieldValue("cp_controlPanel", "yes")}
            value={formik?.values?.cp_controlPanel}
          />
          <label htmlFor="vesselYes">Yes</label>
          <input
            type="radio"
            id="cp_controlPanel"
            name="cp_controlPanel"
            checked={formik?.values?.cp_controlPanel == "no"}
            onChange={() => formik.setFieldValue("cp_controlPanel", "no")}
            value={formik?.values?.cp_controlPanel}
          />
          <label htmlFor="vesselNo">No</label>
        </div>
      </div>
      {formik?.values?.cp_controlPanel === "yes" && (
        <>
          <div className="w-100 px-4 row justify-content-center">
            <div className="row w-100">
              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_Power">Power (Kw Unit)</label>
                <input
                  type="text"
                  id="cp_Power"
                  name="cp_Power"
                  onChange={formik.handleChange}
                  value={formik.values.installedCapacity}
                  className="form-control"
                  placeholder="Enter power"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_areaClassification">
                  Area Classification
                </label>
                <Select
                  name="cp_areaClassification"
                  id="cp_areaClassification"
                  options={[
                    { value: "Safe Area", label: "Safe Area" },
                    { value: "Hazardous Area", label: "Hazardous Area" },
                  ]}
                  placeholder="Select area classification"
                  value={
                    formik?.values?.cp_areaClassification
                      ? {
                          value: formik?.values?.cp_areaClassification,
                          label: formik?.values?.cp_areaClassification,
                        }
                      : null
                  }
                  onChange={(option) =>
                    formik.setFieldValue("cp_areaClassification", option.value)
                  }
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_controlPanelMOC">Control Panel MOC</label>
                <Select
                  name="cp_controlPanelMOC"
                  id="cp_controlPanelMOC"
                  options={
                    formik?.values?.cp_areaClassification === "Hazardous Area"
                      ? [
                          {
                            value: "Aluminium Die Casted",
                            label: "Aluminium Die Casted",
                          },
                        ]
                      : [
                          {
                            value: "C.R.C.A Powder Coated RAL 7032",
                            label: "C.R.C.A Powder Coated RAL 7032",
                          },
                          {
                            value: "C.R.C.A Powder Coated RAL 7035",
                            label: "C.R.C.A Powder Coated RAL 7035",
                          },
                          { value: "SS304", label: "SS304" },
                          { value: "SS316", label: "SS316" },
                        ]
                  }
                  placeholder="Select Control Panel MOC"
                  onChange={(option) =>
                    formik?.setFieldValue("cp_controlPanelMOC", option.value)
                  }
                  value={
                    formik?.values?.cp_controlPanelMOC
                      ? {
                          label: formik?.values?.cp_controlPanelMOC,
                          value: formik?.values?.cp_controlPanelMOC,
                        }
                      : null
                  }
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_controlPanelQuantity">
                  Control Panel Quantity
                </label>
                <input
                  type="number"
                  name="cp_controlPanelQuantity"
                  id="cp_controlPanelQuantity"
                  value={formik?.values?.cp_controlPanelQuantity}
                  className="form-control"
                  placeholder="Enter Control Panel Quantity"
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                />
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_breakingCapacity">Breaking Capacity</label>
                <Select
                  name="cp_breakingCapacity"
                  id="cp_breakingCapacity"
                  options={[
                    { value: "25k", label: "25k" },
                    { value: "36k", label: "36k" },
                    { value: "50k", label: "50k" },
                    { value: "65k", label: "65k" },
                  ]}
                  placeholder="Select Breaking Capacity"
                  value={
                    formik?.values?.cp_breakingCapacity
                      ? {
                          value: formik?.values?.cp_breakingCapacity,
                          label: formik?.values?.cp_breakingCapacity,
                        }
                      : null
                  }
                  onChange={(option) =>
                    formik.setFieldValue("cp_breakingCapacity", option.value)
                  }
                />
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_ipRating">IP Rating</label>
                <Select
                  name="cp_ipRating"
                  placeholder="Select IP Rating"
                  id="cp_ipRating"
                  options={ipRatingOptions}
                  onChange={(option) =>
                    formik?.setFieldValue("cp_ipRating", option.value)
                  }
                  value={
                    formik.values.cp_ipRating
                      ? {
                          label: formik.values.cp_ipRating,
                          value: formik.values.cp_ipRating,
                        }
                      : null
                  }
                  onBlur={formik?.handleBlur}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_voltage">Voltage (Volt)</label>
                <input
                  type="text"
                  id="cp_voltage"
                  name="cp_voltage"
                  onChange={formik.handleChange}
                  value={formik?.values?.voltage}
                  className="form-control"
                  placeholder="Enter voltage"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_controlSupplyVoltage">
                  Control Supply Voltage
                </label>
                <Select
                  name="cp_controlSupplyVoltage"
                  id="cp_controlSupplyVoltage"
                  options={[
                    { value: "240 A.C", label: "240 A.C" },
                    { value: "110 A.C", label: "110 A.C" },
                    { value: "110 D.C", label: "110 D.C" },
                    { value: "24 D.C", label: "24 D.C" },
                  ]}
                  defaultValue={{ value: "240 A.C", label: "240 A.C" }}
                  placeholder="Select Control Supply Voltage"
                  onChange={(option) =>
                    formik.setFieldValue(
                      "cp_controlSupplyVoltage",
                      option.value
                    )
                  }
                  value={
                    formik?.values?.cp_controlSupplyVoltage
                      ? {
                          value: formik?.values?.cp_controlSupplyVoltage,
                          label: formik?.values?.cp_controlSupplyVoltage,
                        }
                      : null
                  }
                />
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_thyristorPanelSelection">
                  Thyristor Panel
                </label>
                <div className="">
                  <div className="row px-2 py-2">
                    <input
                      type="radio"
                      id="cp_thyristorPanelSelection"
                      name="cp_thyristorPanelSelection"
                      className="row gap-3 ml-0"
                      checked={
                        formik?.values?.cp_thyristorPanelSelection === "yes"
                      }
                      onChange={() =>
                        formik.setFieldValue(
                          "cp_thyristorPanelSelection",
                          "yes"
                        )
                      }
                      value={formik?.values?.cp_thyristorPanelSelection}
                    />
                    <label htmlFor="cp_thyristorPanelSelection">Yes</label>
                    <input
                      type="radio"
                      id="cp_thyristorPanelSelection"
                      name="cp_thyristorPanelSelection"
                      className="row gap-3 ml-5"
                      checked={
                        formik?.values?.cp_thyristorPanelSelection ==
                        " Contractor Control"
                      }
                      onChange={() =>
                        formik.setFieldValue(
                          "cp_thyristorPanelSelection",
                          " Contractor Control"
                        )
                      }
                      value={formik?.values?.cp_thyristorPanelSelection}
                    />
                    <label htmlFor="cp_thyristorPanelSelection">No</label>
                  </div>
                  {formik?.values?.cp_thyristorPanelSelection === "yes" && (
                    <>
                      <div className="row px-2 py-2">
                        <input
                          type="radio"
                          id="with_pid_controller"
                          name="cp_thyristorPanel"
                          checked={
                            formik?.values?.cp_thyristorPanel ===
                            "With PID Controller"
                          }
                          onChange={() =>
                            formik.setFieldValue(
                              "cp_thyristorPanel",
                              "With PID Controller "
                            )
                          }
                          value={formik?.values?.cp_thyristorPanel}
                        />
                        <label htmlFor="cp_thyristorPanel">
                          With PID Controller
                        </label>
                      </div>
                      <div className="row px-2 py-2">
                        <input
                          type="radio"
                          id="without_pid_controller"
                          name="cp_thyristorPanel"
                          checked={
                            formik?.values?.cp_thyristorPanel ==
                            "Without PID Controller"
                          }
                          onChange={() =>
                            formik.setFieldValue(
                              "cp_thyristorPanel",
                              "Without PID Controller"
                            )
                          }
                          value={formik?.values?.cp_thyristorPanel}
                        />
                        <label htmlFor="cp_thyristorPanel">
                          Without PID Controller
                        </label>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {formik?.values?.cp_thyristorPanelSelection === "yes" && (
                <>
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                    <label htmlFor="cp_thyristorLag">Thyristor Lag</label>
                    <Select
                      name="cp_thyristorLag"
                      id="cp_thyristorLag"
                      options={[
                        { value: "2 Lag", label: "2 Lag" },
                        { value: "3 Lag", label: "3 Lag" },
                      ]}
                      placeholder="Select Thyristor Lag"
                      value={
                        formik?.values?.cp_thyristorLag
                          ? {
                              value: formik?.values?.cp_thyristorLag,
                              label: formik?.values?.cp_thyristorLag,
                            }
                          : null
                      }
                      onChange={(option) =>
                        formik.setFieldValue("cp_thyristorLag", option.value)
                      }

                      // isClearable
                    />
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                    <label htmlFor="cp_thyristorFiringType">
                      Thyristor Firing Type
                    </label>
                    <Select
                      name="cp_thyristorFiringType"
                      id="cp_thyristorFiringType"
                      options={
                        formik?.values?.cp_thyristorLag === "2 Lag"
                          ? [{ value: "Burst Firing", label: "Burst Firing" }]
                          : [
                              { value: "Burst Firing", label: "Burst Firing" },
                              {
                                value: "Single Cycle Zero Cross Over",
                                label: "Single Cycle Zero Cross Over",
                              },
                              {
                                value: "3 Phase Angle Firing",
                                label: "3 Phase Angle Firing",
                              },
                            ]
                      }
                      placeholder="Select Thyristor Firing Type"
                      onChange={(option) =>
                        formik.setFieldValue(
                          "cp_thyristorFiringType",
                          option.value
                        )
                      }
                      value={
                        formik?.values?.cp_thyristorFiringType
                          ? {
                              value: formik?.values?.cp_thyristorFiringType,
                              label: formik?.values?.cp_thyristorFiringType,
                            }
                          : null
                      }
                    />
                  </div>
                </>
              )}

              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_inputTypeOfController">
                  Input Type Of Controller
                </label>
                <div className="row mt-10">
                  <input
                    type="radio"
                    id="cp_inputTypeOfController"
                    name="cp_inputTypeOfController"
                    className="row gap-3"
                    checked={formik?.values?.cp_inputTypeOfController == "RTD"}
                    onChange={() =>
                      formik.setFieldValue("cp_inputTypeOfController", "RTD")
                    }
                    value={formik?.values?.cp_inputTypeOfController}
                  />
                  <label htmlFor="cp_inputTypeOfController">RTD</label>
                </div>
                <div className="row">
                  <input
                    type="radio"
                    id="cp_inputTypeOfController"
                    name="cp_inputTypeOfController"
                    className="row gap-3"
                    checked={
                      formik?.values?.cp_inputTypeOfController === "MILI AMP"
                    }
                    onChange={() =>
                      formik.setFieldValue(
                        "cp_inputTypeOfController",
                        "MILI AMP"
                      )
                    }
                    value={formik?.values?.cp_inputTypeOfController}
                  />
                  <label htmlFor="cp_inputTypeOfController">MILI AMP</label>
                </div>
                <div className="row">
                  <input
                    type="radio"
                    id="cp_inputTypeOfController"
                    name="cp_inputTypeOfController"
                    className="row gap-3"
                    checked={
                      formik?.values?.cp_inputTypeOfController ===
                      "THERMOCOUPLE"
                    }
                    onChange={() =>
                      formik.setFieldValue(
                        "cp_inputTypeOfController",
                        "THERMOCOUPLE"
                      )
                    }
                    value={formik?.values?.cp_inputTypeOfController}
                  />
                  <label htmlFor="cp_inputTypeOfController">THERMOCOUPLE</label>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_typeTestRequirement">
                  Type Test Requirement
                </label>
                <div className="mb-15">
                  <input
                    type="radio"
                    name="cp_typeTestRequirement"
                    value="yes"
                    checked={formik?.values?.cp_typeTestRequirement === "yes"}
                    onChange={
                      formik?.values?.cp_typeTestRequirement === "yes"
                        ? () =>
                            formik.setFieldValue("cp_typeTestRequirement", "")
                        : () =>
                            formik.setFieldValue(
                              "cp_typeTestRequirement",
                              "yes"
                            )
                    }
                  />{" "}
                  Yes
                  <input
                    type="radio"
                    name="cp_typeTestRequirement"
                    value="no"
                    checked={formik?.values?.cp_typeTestRequirement === "no"}
                    onChange={
                      formik?.values?.cp_typeTestRequirement === "no"
                        ? () =>
                            formik.setFieldValue("cp_typeTestRequirement", "")
                        : () =>
                            formik.setFieldValue("cp_typeTestRequirement", "no")
                    }
                  />{" "}
                  No
                </div>
                {formik?.values?.cp_typeTestRequirement === "yes" && (
                  <input
                    type="text"
                    name="cp_typeTestStandard"
                    value="IEC61439"
                    readOnly
                    className="form-control"
                  />
                )}
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_incomerBusDust">Incomer Bus Duct</label>
                <div>
                  <input
                    type="radio"
                    name="cp_incomerBusDust"
                    value="yes"
                    checked={formik?.values?.cp_incomerBusDust === "yes"}
                    onChange={
                      formik?.values?.cp_incomerBusDust === "yes"
                        ? () => formik.setFieldValue("cp_incomerBusDust", "no")
                        : () => formik.setFieldValue("cp_incomerBusDust", "yes")
                    }
                  />{" "}
                  Yes
                  <input
                    type="radio"
                    name="cp_incomerBusDust"
                    value="no"
                    checked={formik?.values?.cp_incomerBusDust === "no"}
                    onChange={
                      formik?.values?.cp_incomerBusDust === "no"
                        ? () => formik.setFieldValue("cp_incomerBusDust", "yes")
                        : () => formik.setFieldValue("cp_incomerBusDust", "no")
                    }
                  />{" "}
                  No
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_frequency">Frequency (Hz)</label>
                <input
                  type="text"
                  id="cp_frequency"
                  name="cp_frequency"
                  onChange={formik.handleChange}
                  value={formik.values.frequency}
                  className="form-control"
                  placeholder="Enter frequency"
                />
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_typeOfControlling">
                  Type of Controlling
                </label>
                <Select
                  name="cp_typeOfControlling"
                  id="cp_typeOfControlling"
                  options={[
                    { value: "Contractor", label: "Contractor" },
                    { value: "Thyristor", label: "Thyristor" },
                    { value: "SCR Controller", label: "SCR Controller" },
                    {
                      value: "Thyristor + Contractor",
                      label: "Thyristor + Contractor",
                    },
                  ]}
                  placeholder="Type of Controlling"
                  value={
                    formik?.values?.cp_typeOfControlling
                      ? {
                          value: formik?.values?.cp_typeOfControlling,
                          label: formik?.values?.cp_typeOfControlling,
                        }
                      : null
                  }
                  onChange={(option) =>
                    formik.setFieldValue("cp_typeOfControlling", option.value)
                  }
                />
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_cableEntry">Cable Entry</label>
                <Select
                  name="cp_cableEntry"
                  id="cp_cableEntry"
                  options={[
                    { value: "Top", label: "Top" },
                    { value: "Bottom", label: "Bottom" },
                  ]}
                  placeholder="Select Cable Entry"
                  // defaultValue={{ value: "Top", label: "Top" }}
                  defaultValue={{ value: "Top", label: "Top" }}
                  onChange={(option) =>
                    formik.setFieldValue("cp_cableEntry", option.value)
                  }
                  value={
                    formik?.values?.cp_cableEntry
                      ? {
                          value: formik?.values?.cp_cableEntry,
                          label: formik?.values?.cp_cableEntry,
                        }
                      : null
                  }
                />
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_skinTemperatureController">
                  Skin Temperature Controller
                </label>
                <input
                  type="number"
                  name="cp_skinTemperatureController"
                  id="cp_skinTemperatureController"
                  value={formik?.values?.cp_skinTemperatureController}
                  className="form-control"
                  placeholder="Skin Temperature Controller"
                  onChange={formik.handleChange}
                  onBlur={formik?.handleBlur}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_tubeSheetTemperatureController">
                  Tube Sheet Temperature Controller
                </label>
                <input
                  type="text"
                  name="cp_tubeSheetTemperatureController"
                  id="cp_tubeSheetTemperatureController"
                  value={formik?.values?.cp_tubeSheetTemperatureController}
                  onChange={formik?.handleChange}
                  className="form-control"
                  placeholder="Tube Sheet Temperature"
                />
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_processTemperatureController">
                  Process Temperature Controller
                </label>
                <input
                  type="number" 
                  name="cp_processTemperatureController"
                  id="cp_processTemperatureController"
                  value={formik?.values?.cp_processTemperatureController}
                  className="form-control"
                  placeholder="Process Temperature Controller"
                  onChange={formik.handleChange}
                  onBlur={formik?.handleBlur}
                />
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_busBarMaterial">Bus Bar Material</label>
                <Select
                  name="cp_busBarMaterial"
                  id="cp_busBarMaterial"
                  options={[
                    { value: "Copper", label: "Copper" },
                    { value: "Aluminium", label: "Aluminium" },
                  ]}
                  // defaultValue={{ value: "Copper", label: "Copper" }}
                  placeholder="Select Bus Bar Material"
                  onChange={(option) =>
                    formik.setFieldValue("cp_busBarMaterial", option.value)
                  }
                  value={
                    formik?.values?.cp_busBarMaterial
                      ? {
                          value: formik?.values?.cp_busBarMaterial,
                          label: formik?.values?.cp_busBarMaterial,
                        }
                      : null
                  }
                />
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_packingType">Packing Type</label>
                <Select
                  name="cp_packingType"
                  id="cp_packingType"
                  options={[
                    { value: "Wooden", label: "Wooden" },
                    { value: "Sea Worthy", label: "Sea Worthy" },
                  ]}
                  // defaultValue={{ value: "Wooden", label: "Wooden" }}
                  placeholder="Select Packing Type"
                  onChange={(option) =>
                    formik.setFieldValue("cp_packingType", option.value)
                  }
                  value={
                    formik?.values?.cp_packingType
                      ? {
                          value: formik?.values?.cp_packingType,
                          label: formik?.values?.cp_packingType,
                        }
                      : null
                  }
                />
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_enclosureMake">Enclosure Make</label>
                <Select
                  name="cp_enclosureMake"
                  id="cp_enclosureMake"
                  options={[
                    { value: "Rittal", label: "Rittal" },
                    { value: "ABB", label: "ABB" },
                    { value: "Pyrotech", label: "Pyrotech" },
                    { value: "Reputated", label: "Reputated" },
                  ]}
                  placeholder="Select Enclosure Make"
                  onChange={(option) =>
                    formik.setFieldValue("cp_enclosureMake", option.value)
                  }
                  value={
                    formik?.values?.cp_enclosureMake
                      ? {
                          value: formik?.values?.cp_enclosureMake,
                          label: formik?.values?.cp_enclosureMake,
                        }
                      : null
                  }
                  onBlur={formik?.handleBlur}
                />
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_enclosureSizeLength">
                  Enclosure Size (Length)
                </label>
                <input
                  type="number"
                  name="cp_enclosureSizeLength"
                  id="cp_enclosureSizeLength"
                  value={formik?.values?.cp_enclosureSizeLength}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter Enclosure Size (Length)"
                  className="form-control"
                />
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_enclosureSizeWidth">
                  Enclosure Size (Width)
                </label>
                <input
                  type="number"
                  name="cp_enclosureSizeWidth"
                  id="cp_enclosureSizeWidth"
                  value={formik?.values?.cp_enclosureSizeWidth}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter Enclosure Size (Width)"
                  className="form-control"
                />
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_enclosureSizeHeight">
                  Enclosure Size (Height)
                </label>
                <input
                  type="number"
                  name="cp_enclosureSizeHeight"
                  id="cp_enclosureSizeHeight"
                  value={formik?.values?.cp_enclosureSizeHeight}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter Enclosure Size (Height)"
                  className="form-control"
                />
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_enclosureCompartment">
                  Enclosure Compartment
                </label>
                <Select
                  name="cp_enclosureCompartment"
                  id="cp_enclosureCompartment"
                  options={[
                    { value: "Compartment", label: "Compartment" },
                    { value: "Non Compartment", label: "Non Compartment" },
                  ]}
                  placeholder="Select Enclosure Compartment"
                  onChange={(option) =>
                    formik.setFieldValue(
                      "cp_enclosureCompartment",
                      option.value
                    )
                  }
                  value={
                    formik?.values?.cp_enclosureCompartment
                      ? {
                          value: formik?.values?.cp_enclosureCompartment,
                          label: formik?.values?.cp_enclosureCompartment,
                        }
                      : null
                  }
                  onBlur={formik?.handleBlur}
                />
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_enclosureMaterial">Enclosure Material</label>
                <Select
                  name="cp_enclosureMaterial"
                  id="cp_enclosureMaterial"
                  options={[
                    { value: "1.6mm", label: "1.6mm" },
                    { value: "2mm", label: "2mm" },
                  ]}
                  placeholder="Select Enclosure Material"
                  onChange={(option) =>
                    formik.setFieldValue("cp_enclosureMaterial", option.value)
                  }
                  value={
                    formik?.values?.cp_enclosureMaterial
                      ? {
                          value: formik?.values?.cp_enclosureMaterial,
                          label: formik?.values?.cp_enclosureMaterial,
                        }
                      : null
                  }
                  onBlur={formik?.handleBlur}
                />
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_softStart">Soft Start</label>
                <div>
                  <input
                    type="radio"
                    name="cp_softStart"
                    value="yes"
                    checked={formik?.values?.cp_softStart === "yes"}
                    onChange={
                      formik?.values?.cp_softStart === "yes"
                        ? () => formik.setFieldValue("cp_softStart", "no")
                        : () => formik.setFieldValue("cp_softStart", "yes")
                    }
                  />{" "}
                  Yes
                  <input
                    type="radio"
                    name="cp_softStart"
                    value="no"
                    checked={formik?.values?.cp_softStart === "no"}
                    onChange={
                      formik?.values?.cp_softStart === "no"
                        ? () => formik.setFieldValue("cp_softStart", "yes")
                        : () => formik.setFieldValue("cp_softStart", "no")
                    }
                  />{" "}
                  No
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                <label htmlFor="cp_sil22Requirement">
                  S.I.L 22 Requirement
                </label>
                <div>
                  <input
                    type="radio"
                    name="cp_sil22Requirement"
                    value="yes"
                    checked={formik?.values?.cp_sil22Requirement === "yes"}
                    onChange={
                      formik?.values?.cp_sil22Requirement === "yes"
                        ? () =>
                            formik.setFieldValue("cp_sil22Requirement", "no")
                        : () =>
                            formik.setFieldValue("cp_sil22Requirement", "yes")
                    }
                  />{" "}
                  Yes
                  <input
                    type="radio"
                    name="cp_sil22Requirement"
                    value="no"
                    checked={formik?.values?.cp_sil22Requirement === "no"}
                    onChange={
                      formik?.values?.cp_sil22Requirement === "no"
                        ? () =>
                            formik.setFieldValue("cp_sil22Requirement", "yes")
                        : () =>
                            formik.setFieldValue("cp_sil22Requirement", "no")
                    }
                  />{" "}
                  No
                </div>
              </div>
            </div>
          </div>
          {formik &&
            formik?.values?.multistackvessel &&
            formik?.values?.bank &&
            formik?.values?.heatDutyKW && (
              <div className="d-flex col-md-6 col-lg-6 col-sm-12">
                <table className="table border border-secondary">
                  <thead>
                    <tr>
                      <th
                        style={{
                          border: "1px solid #ccc",
                          padding: "8px",
                        }}
                      >
                        <strong>Bundle</strong>
                      </th>
                      {Array.from({ length: totalBank }).map((_, i) => (
                        <th
                          key={i}
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                          }}
                        >
                          <strong>Bank {i + 1}</strong>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {formik?.values?.bundleTableData?.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                          }}
                        >
                          Bundle {rowIndex + 1} -{bundleHeatLoad.toFixed(2)}
                        </td>
                        {row?.bundle.map((col, colIndex) => (
                          <td
                            key={colIndex}
                            style={{
                              border: "1px solid #ccc",
                              padding: "8px",
                            }}
                          >
                            <input
                              style={{
                                width: "100%",
                                minWidth: "30px",
                                boxSizing: "border-box",
                                padding: "5px",
                              }}
                              name={`bundleTableData[${rowIndex}].bundle[${colIndex}].bank`}
                              id={`bundleTableData${rowIndex}.bundle${colIndex}.bank`}
                              type="text"
                              className="form-control"
                              placeholder={(bundleHeatLoad / totalBank).toFixed(
                                2
                              )}
                              value={col.bank}
                              onChange={(e) =>
                                handleInputChange(e, rowIndex, colIndex)
                              }
                              readOnly
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
        </>
      )}
      <BomFormula formik={formik} />
      <hr />
    </div>
  );
}

export default Screen10;
