import React, { useState, useEffect } from "react";

function HeaterBundleWithVessel({
  formik,
  tableParentDiv,
  tableClass,
  tdClass,
  trClass,
}) {
  const [template, setTemplate] = useState("");
  const [data, setData] = useState({ name: "John Doe" });
  const templateDir = "../../assets/heaterbundle.html";

  useEffect(() => {
    const fetchTemplate = async () => {
      const response = await fetch(templateDir);
      const text = await response.text();
      setTemplate(text);
    };

    fetchTemplate();
  }, []);

  const replaceVariables = (html, data) => {
    const regex = /\{\{([^}]+)\}\}/g;
    return html.replace(regex, (match, variable) => data[variable] || "");
  };

  const installedCapacity = parseFloat(formik.values.installedCapacity) || 0;
  const totalBank = parseInt(formik.values.bank) || 0;
  const effectiveVessel = parseInt(formik.values.multistackvessel) || 1;
  const bundleHeatLoad = installedCapacity / effectiveVessel;

  const handleInputChange = (e, rowIndex, colIndex) => {
    const { value } = e.target;
    const updatedTableData = [...formik.values.bundleTableData];
    updatedTableData[rowIndex].bundle[colIndex].bank = value;

    const total =
      bundleHeatLoad - updatedTableData[rowIndex].bundle[colIndex].bank;
    const bank = formik.values.bank - 1;
    const eachData = total / bank;
    updatedTableData[rowIndex].bundle.map((data, index) => {
      if (index !== colIndex) {
        data.bank = eachData;
      }
    });

    formik.setFieldValue("bundleTableData", updatedTableData);
  };

  const renderedHtml = replaceVariables(template, data);

  const handleSurfaceArea = (e) => {
    const individualElementWattage =
      parseFloat(formik?.values?.heatDutyKW * 1000) /
      formik?.values?.multistackvessel;
    const wattDensity = parseFloat(formik?.values?.wattdensity);
    const totalElementWattage =
      individualElementWattage / (wattDensity * 10000);
    formik.setFieldValue("totalsurfacearea", totalElementWattage.toFixed(2));
  };

  const heattransfercoefficient = (e) => {
    const heatTransferCoefficient =
      parseFloat(formik?.values?.heatDutyKW * 1000) /
      (formik?.values?.wattdensity * (350 - formik.values.outletTemp));
    formik.setFieldValue(
      "heatTransferCoefficient",
      heatTransferCoefficient.toFixed(2)
    );
  };

  useEffect(() => {
    handleSurfaceArea();
    heattransfercoefficient();
  }, [
    formik?.values?.heatDutyKW,
    formik?.values?.multistackvessel,
    formik?.values?.wattdensity,
    formik.values.outletTemp,
  ]);

  return (
    <div>
      <div>
        <div
          style={{
            border: "1px solid black",
            margin: "10px 0",
            position: "relative",
          }}
        >
          <img
            src="/static/media/3000.2aa05d749fa60763de6e.png"
            alt="abc"
            style={{ height: "auto", width: "200px", margin: "10px " }}
          />
          <h1
            style={{
              fontSize: "25px",
              position: "absolute",
              top: "25%",
              left: "27%",
              margin: "10px 0",
            }}
          >
            TECHNICAL DATA SHEET - ELECTRIC HEATER WITH VESSEL
          </h1>
        </div>
        {/* binding done */}
        <div style={tableParentDiv}>
          <table style={tableClass}>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Customer:</strong>
              </td>
              <td style={tdClass}>{formik?.values?.customer || "N/A"}</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Address</strong>
              </td>
              <td style={tdClass}>{formik?.values?.address || "N/A"}</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Project Name</strong>
              </td>
              <td style={tdClass}>{formik?.values?.projectName || "N/A"}</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Item Description</strong>
              </td>
              <td style={tdClass}>
                {formik?.values?.itemDescription || "N/A"}
              </td>
            </tr>
          </table>
          <table style={tableClass}>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>ENQ. No & Date</strong>
              </td>
              <td style={tdClass}>
                {formik?.values?.enqNo || "N/A"}&nbsp;
                {formik?.values?.enqDate || "N/A"}
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>OFFER NO & date</strong>
              </td>
              <td style={tdClass}>
                {formik?.values?.offerNo || "N/A"}&nbsp;
                {formik?.values?.offerDate || "N/A"}
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>OFFER REV No</strong>
              </td>
              <td style={tdClass}>{formik?.values?.offerRevNo || "N/A"}</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>TAG NO:-</strong>
              </td>
              <td style={tdClass}>{formik?.values?.tagNo || "N/A"}</td>
            </tr>
          </table>
        </div>

        <div style={tableParentDiv}>
          {/* PROCESS PARAMETERS */}
          <table style={tableClass}>
            <tr style={trClass}>
              <td colSpan="5" bgcolor="#CCCCCC" style={tdClass}>
                <div align="center">
                  <strong>PROCESS PARAMETERS</strong>
                </div>
              </td>
            </tr>
            <tr bgcolor="#CCCCCC" style={trClass}>
              <td style={tdClass}>
                <strong>Specification</strong>
              </td>
              <td style={tdClass}></td>
              <td style={tdClass}>
                <strong>Unit</strong>
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Process Fluid</strong>
              </td>
              <td style={tdClass}>{formik?.values?.processFluid || "N/A"}</td>
              <td style={tdClass}> </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Mass Flow Rate</strong>
              </td>
              <td style={tdClass}>{formik?.values?.massFlowRate || "N/A"}</td>
              <td style={tdClass}>M3/hr</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Sp. Heat</strong>
              </td>
              <td style={tdClass}>{formik?.values?.specificHeatKJ || "N/A"}</td>
              <td style={tdClass}>kj/kg.°C</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Viscosity</strong>
              </td>
              <td style={tdClass}>{formik?.values?.viscosity || "N/A"}</td>
              <td style={tdClass}>centipose</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Thermal Conductivity</strong>
              </td>
              <td style={tdClass}>
                {formik?.values?.thermalConductivity || "N/A"}
              </td>
              <td style={tdClass}>w/m°k</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Mol. Weight</strong>
              </td>
              <td style={tdClass}>
                {formik?.values?.molecularweight || "N/A"}
              </td>
              <td style={tdClass}> </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Inlet Temperature</strong>
              </td>
              <td style={tdClass}>{formik?.values?.startingTemp || "N/A"}</td>
              <td style={tdClass}>°{formik?.values?.unit || "N/A"}</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Outlet Temperature</strong>
              </td>
              <td style={tdClass}>{formik?.values?.outletTemp || "N/A"}</td>
              <td style={tdClass}>°{formik?.values?.unit || "N/A"}</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Design temperature</strong>
              </td>
              <td style={tdClass}>
                {formik?.values?.designTemperature || "N/A"}
              </td>
              <td style={tdClass}>°{formik?.values?.unit || "N/A"}</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Inlet Pressure</strong>
              </td>
              <td style={tdClass}>{formik?.values?.inletPressure || "N/A"}</td>
              <td style={tdClass}>
                {formik?.values?.inletPressureUnit || "N/A"}
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Outlet Pressure</strong>
              </td>
              <td style={tdClass}>{formik?.values?.outletPressure || "N/A"}</td>
              <td style={tdClass}>
                {formik?.values?.outletPressureUnit || "N/A"}
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Pressure Drop</strong>
              </td>
              <td style={tdClass}>
                {formik?.values?.allowedPressureDrop || "N/A"}
              </td>
              <td style={tdClass}>
                {formik?.values?.allowedPressureDropUnit || "N/A"}
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Design Pressure </strong>
              </td>
              <td style={tdClass}>{formik?.values?.designPressure || "N/A"}</td>
              <td style={tdClass}>
                {formik?.values?.allowedPressureDropUnit || "N/A"}
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>MDMT</strong>
              </td>
              <td style={tdClass}>{formik?.values?.mdmt || "N/A"}</td>
              <td style={tdClass}>°{formik?.values?.unit || "N/A"} </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Corrosion allowance</strong>
              </td>
              <td style={tdClass}>
                {formik?.values?.corrosionAllowance || "N/A"}
              </td>
              <td style={tdClass}>mm</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Radiography</strong>
              </td>
              <td style={tdClass}>{formik?.values?.radiography || "N/A"}</td>
              <td style={tdClass}> </td>
            </tr>
          </table>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              borderCollapse: "collapse",
            }}
          >
            <table style={tableClass}>
              <tr style={trClass}>
                <td colSpan="6" bgcolor="#CCCCCC" style={tdClass}>
                  <div align="center">
                    <strong>ELECTRICAL PARAMETERS</strong>
                  </div>
                </td>
              </tr>
              <tr bgcolor="#CCCCCC" style={trClass}>
                <td style={tdClass}> </td>
                <td colSpan="5" style={tdClass}></td>
              </tr>
              <tr style={trClass}>
                <td style={tdClass}>
                  <strong>Total Power</strong>
                </td>
                <td colSpan="2" style={tdClass}>
                  {formik?.values?.installedCapacity * 1000 || "N/A"}
                </td>
                <td style={tdClass}colSpan={3}>W</td>
              </tr>
              <tr style={trClass}>
                <td style={tdClass}>
                  <strong>Supply Voltage</strong>
                </td>
                <td colSpan="2" style={tdClass}>
                {formik?.values?.voltage || "N/A"}
                </td>
                <td style={tdClass} colSpan={3}>V</td>
              </tr>
              <tr style={trClass}>
                <td style={tdClass}>
                  <strong>Connection Type</strong>
                </td>
                <td colSpan="2" style={tdClass}>
                  {formik?.values?.connection || "N/A"}
                </td>
                <td style={tdClass} colSpan={3}> </td>

              </tr>
              <tr style={trClass}>
                <td style={tdClass}>
                  <strong>Total No. of Elements</strong>
                </td>
                <td colSpan="2" style={tdClass}>
                  {formik?.values?.totalnumberofelements || "N/A"}
                </td>
                <td style={tdClass} colSpan={3}>No&#39;s</td>
              </tr>
              <tr style={trClass}>
                <td style={tdClass}>
                  <strong>Connected Elements</strong>
                </td>
                <td colSpan="2" style={tdClass}>
                  {formik?.values?.numberofelementsconnected || "N/A"}
                </td>
                <td style={tdClass} colSpan={3}>No&#39;s</td>
              </tr>
              <tr style={trClass}>
                <td style={tdClass}>
                  <strong>Spare Elements</strong>
                </td>
                <td colSpan="2" style={tdClass}>
                  {formik?.values?.numberofelementsspare || "N/A"}
                </td>
                <td style={tdClass} colSpan={3}>No&#39;s</td>
              </tr>
              <tr style={trClass}>
                <td style={tdClass}>
                  <strong>Individual Element Rating</strong>
                </td>
                <td colSpan="2" style={tdClass}>
                  {formik?.values?.individualelementmaxwattage || "N/A"}
                </td>
                <td style={tdClass} colSpan={3}>KW</td>
            
              </tr>
              <tr style={trClass}>
                <td style={tdClass}>
                  <strong>
                    Frequency
                  </strong>
                </td>
                <td colSpan="2" style={tdClass}>{formik?.values?.frequency || "N/A"}</td>
                <td style={tdClass} colSpan={3}>Hz</td>
              </tr>
              <tr style={trClass}>
                <td style={tdClass}>
                  <strong>Individual Element design voltage</strong>
                </td>
                <td colSpan="2" style={tdClass}>{formik?.values?.connection === "Three Phase Star" ? (formik?.values?.voltage_calculation) : formik?.values?.voltage || "N/A"}</td>
                <td style={tdClass} colSpan={3}>V</td>
              </tr>
              <tr
                style={{
                  borderCollapse: "collapse",
                  height: "22px",
                  border: "none",
                }}
              >
                <td rowSpan="2" style={tdClass}>
                  <strong>Watt Density ( w/cm2)</strong>
                </td>
                <td style={tdClass} colSpan={5}>{parseFloat(formik?.values?.wattdensityactual).toFixed(2) || "N/A"}</td>

                
              </tr>
            </table>
            <table style={tableClass}>
              <tr style={trClass}>
                <td
                  style={tdClass}
                  colSpan={formik.values.bank + 1}
                  bgcolor="#cccccc"
                >
                  <div align="center">
                    <strong>Power Break-up (KW)</strong>
                  </div>
                </td>
              </tr>
              <tr style={trClass}>
                <td style={tdClass}>
                  <strong>Bundle</strong>
                </td>
                {Array.from({ length: totalBank }).map((_, i) => (
                  <td key={i} style={tdClass}>
                    <strong>Bank {i + 1}</strong>
                  </td>
                ))}
              </tr>
              <tbody>
                {formik?.values?.bundleTableData?.map((row, rowIndex) => (
                  <tr key={rowIndex} style={trClass}>
                    <td style={tdClass}>
                      Bundle {rowIndex + 1} -{bundleHeatLoad.toFixed(2)}
                    </td>
                    {row?.bundle.map((col, colIndex) => (
                      <td key={colIndex} style={tdClass}>
                        <div>
                          {
                            formik?.values?.bundleTableData[rowIndex]?.bundle[
                              colIndex
                            ]?.bank
                          }
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div style={tableParentDiv}>
          <table style={tableClass}>
            <tr style={trClass}>
              <td colSpan="5" bgcolor="#CCCCCC" style={tdClass}>
                <div align="center">
                  <strong>MATERIAL OF CONSTRUCTION</strong>
                </div>
                <div align="center"></div>
                <div align="center"></div>
                <div align="center"></div>
                <div align="center"></div>
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Part Description</strong>
              </td>
              <td style={tdClass}>
                <strong>MOC</strong>
              </td>
              <td style={tdClass}>
                <strong>Qty</strong>
              </td>
              <td style={tdClass}>
                <strong>UOM</strong>
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Heater flange / Tube sheet</strong>
              </td>

              <td style={tdClass}>{formik?.values?.sheathmaterial || "N/A"}</td>
              <td style={tdClass}>1</td>
              <td style={tdClass}>PC</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Vessel Main Flange</strong>
              </td>
              <td style={tdClass}>{formik?.values?.sheathmaterial || "N/A"}</td>
              <td style={tdClass}>1</td>
              <td style={tdClass}>PC</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Main Shell</strong>
              </td>
              <td style={tdClass}>{formik?.values?.FlangeMaterial || "N/A"}</td>
              <td style={tdClass}>1</td>
              <td style={tdClass}>PC</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Nozzle</strong>
              </td>
              <td style={tdClass}>{formik?.values?.vesselMaterial || "N/A"}</td>
              <td style={tdClass}>10</td>
              <td style={tdClass}>PC</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Nozzle flanges</strong>
              </td>
              <td style={tdClass}>{formik?.values?.vesselMaterialCode || "N/A"}</td>
              <td style={tdClass}>10</td>
              <td style={tdClass}>PC</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Reducers / End cap / Dish End</strong>
              </td>
              <td style={tdClass}>{formik?.values?.reducers || "N/A"}</td>
              <td style={tdClass}>10</td>
              <td style={tdClass}>PC</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Saddle Supports</strong>
              </td>
              <td style={tdClass}>{formik?.values?.sadlemoc || "N/A"}</td>
              <td style={tdClass}>1</td>
              <td style={tdClass}>Set</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Baffles</strong>
              </td>
              <td style={tdClass}>{formik?.values?.bafflematerial || "N/A"}</td>
              <td style={tdClass}>
                {formik?.values?.numberofbaffles || "N/A"}
              </td>
              <td style={tdClass}>PC</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Tie Rods</strong>
              </td>
              <td style={tdClass}>{formik?.values?.tiematerial || "N/A"}</td>
              <td style={tdClass}>{formik?.values?.tierodquantity || "N/A"}</td>
              <td style={tdClass}>Pc</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Element Outer sheath</strong>
              </td>
              <td style={tdClass}>
                {formik?.values?.sheathmaterial || "N/A"} /
                {formik?.values?.sheathtubetype || "N/A"}
              </td>
              <td style={tdClass}>30</td>
              <td style={tdClass}>PC</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Gaskets</strong>
              </td>
              <td style={tdClass}>CS SPIRAL WOUND GRAPHITE FILLED</td>
              <td style={tdClass}>As required</td>
              <td style={tdClass}>Set</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Termial Enclosure</strong>
              </td>
              <td style={tdClass}>CS</td>
              <td style={tdClass}>1</td>
              <td style={tdClass}>Set</td>
            </tr>
          </table>
          <table style={tableClass}>
            <tr style={trClass}>
              <td colSpan="6" bgcolor="#CCCCCC" style={tdClass}>
                <div align="center">
                  <strong>Nozzle Identification</strong>
                </div>
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Nozzle Identification</strong>
              </td>
              <td style={tdClass}>
                <strong>Size</strong>
              </td>
              <td style={tdClass}>
                <strong>Qty</strong>
              </td>
              <td style={tdClass}>
                <strong>Orientation</strong>
              </td>
              <td style={tdClass}>
                <strong>Elevation</strong>
              </td>
              <td style={tdClass}>
                <strong>Type</strong>
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Main shell</strong>
              </td>
              <td style={tdClass}>
            {formik?.values?.flangeSize || "N/A"} &nbsp;" NB, SCH
                &nbsp;
                {formik?.values?.schedule || "N/A"}
                &nbsp;&nbsp;{formik?.values?.FlangeClass || "N/A"} 
              </td>
              <td style={tdClass} align="center">
                {formik.values.multistackvessel || "N/A"}
              </td>
              <td style={tdClass}>
                {formik.values.installationPosition || "N/A"}
              </td>
              <td style={tdClass}>
                N/A
              </td>
              <td style={tdClass}>
                {formik?.values?.vesselrangetype || "N/A"}
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Inlet(N1)</strong>
              </td>
              <td style={tdClass}>
                {formik?.values?.nozzle[0]?.size || "N/A"} &nbsp;" NB, SCH
                &nbsp;
                {formik?.values?.nozzle[0]?.schedules || "N/A"}
                &nbsp;&nbsp;{formik?.values?.nozzle[0]?.flangeRating || "N/A"} 
              </td>
              <td style={tdClass} align="center">
                {formik?.values?.nozzle[0]?.inletNozzle === true ? "1" : "N/A"}
              </td>
              <td style={tdClass}>
                0 Deg
              </td>
              <td style={tdClass}>
                150 mm
              </td>
              <td style={tdClass}>
                {formik?.values?.nozzle[0]?.flangeType || "N/A"}
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Outlet(N2)</strong>
              </td>
              <td style={tdClass}>
                {formik?.values?.nozzle[1]?.size || "N/A"} &nbsp;" NB,
                SCH &nbsp;
                {formik?.values?.nozzle[1]?.schedules || "N/A"}
                &nbsp;&nbsp;{formik?.values?.nozzle[1]?.flangeRating || "N/A"} 
              </td>
              <td style={tdClass} align="center">
                {formik?.values?.nozzle[1].outletNozzle === true ? "1" : "N/A"}
              </td>
              <td style={tdClass}>
                0 Deg
              </td>
              <td style={tdClass}>
               150 mm
              </td>
              <td style={tdClass}>
                {formik?.values?.nozzle[1]?.flangeType || "N/A"}
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Drain(N3)</strong>
              </td>
              <td style={tdClass}>
                {formik?.values?.nozzle[3]?.size || "N/A"} &nbsp;" NB,
                SCH &nbsp;
                {formik?.values?.nozzle[3]?.schedules || "N/A"}
                &nbsp;&nbsp;{formik?.values?.nozzle[3]?.flangeRating || "N/A"} 
              </td>
              <td style={tdClass} align="center">
                {formik?.values?.nozzle[3].drain === true ? "1" : "N/A"}
              </td>
              <td style={tdClass}>
                180 Deg
              </td>
              <td style={tdClass}>
                150 mm
              </td>
              <td style={tdClass}>
                {formik?.values?.nozzle[3]?.flangeType || "N/A"}
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Vent(N4)</strong>
              </td>
              <td style={tdClass}>
               {formik?.values?.nozzle[2]?.size || "N/A"} &nbsp;" NB,
                SCH &nbsp;
                {formik?.values?.nozzle[2]?.schedules || "N/A"}
                &nbsp;&nbsp;{formik?.values?.nozzle[2]?.flangeRating || "N/A"} 
              </td>
              <td style={tdClass} align="center">
                {formik?.values?.nozzle[2].vent === true ? "1" : "N/A"}
              </td>
              <td style={tdClass}>
                0 Deg
              </td>
              <td style={tdClass}>
                150 mm
              </td>
              <td style={tdClass}>
                {formik?.values?.nozzle[2]?.flangeType || "N/A"}
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Process</strong>
              </td>
              <td style={tdClass}>
                {formik?.values?.nozzle[4]?.size || "N/A"} &nbsp;" NB,
                SCH  &nbsp;
                {formik?.values?.nozzle[4]?.schedules || "N/A"}
                &nbsp;&nbsp;{formik?.values?.nozzle[4]?.flangeRating || "N/A"}
              </td>

              <td style={tdClass} align="center">
                {formik?.values?.nozzle[4].process === true ? "1" : "N/A"}
              </td>
              <td style={tdClass}>
                0 Deg
              </td>
              <td style={tdClass}>
                150 mm
              </td>
              <td style={tdClass}>
                {formik?.values?.nozzle[4]?.flangeType || "N/A"}
              </td>
            </tr>
            <tr style={trClass}>
              <td colSpan="6" bgcolor="#CCCCCC" style={tdClass}>
                <div align="center">
                  <strong>Other Dimensions & construction details</strong>
                </div>
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass} colSpan="3">
                <strong>Element Diameter</strong>
              </td>
              <td style={tdClass} colSpan="2">
                {formik?.values?.heatersheathtubedia || "N/A"}
              </td>
              <td style={tdClass}>mm</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass} colSpan="3">
                <strong>Approx. Vessel Length</strong>
              </td>
              <td style={tdClass} colSpan="2">
                {formik?.values?.bundleimmersionlength && (parseFloat(formik.values.bundleimmersionlength) + 150) || "N/A"}
              </td>
              <td style={tdClass}>mm</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass} colSpan="3">
                <strong>Bundle Immersion Length</strong>
              </td>
              <td style={tdClass} colSpan="2">
                {formik?.values?.bundleimmersionlength || "N/A"}
              </td>
              <td style={tdClass}>mm</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass} colSpan="3">
                <strong>Active Length</strong>
              </td>
              <td style={tdClass} colSpan="2">
                {formik?.values?.maxactivehotlength || "N/A"}
              </td>
              <td style={tdClass}>mm</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass} colSpan="3">
                <strong>Tube sheet to Terminal box air gap</strong>
              </td>
              <td style={tdClass} colSpan="2">
                {formik?.values?.distbwheaterflangetoterminalbox || "N/A"}
              </td>
              <td style={tdClass}>mm</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass} colSpan="3">
                <strong>Element fixing method </strong>
              </td>
              <td style={tdClass} colSpan="3">
                {formik?.values?.fixingofheatingelements || "N/A"}
              </td>

            </tr>
            <tr style={trClass}>
              <td style={tdClass} colSpan="3">
                <strong>Baffle Plate Type</strong>
              </td>
              <td style={tdClass} colSpan="3">
                {formik.values.baffletype || "N/A"}
              </td>
            </tr>
          </table>
        </div>
        <div style={tableParentDiv}>
          <table style={tableClass}>
            <tr style={trClass}>
              <td colSpan="5" bgcolor="#CCCCCC" style={tdClass}>
                <div align="center">
                  <strong>INSTRUMENTS &amp; TEMPERATURE SENSORS DETAILS</strong>
                </div>
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Description</strong>
              </td>
              <td style={tdClass}>
                <strong>Qty</strong>
              </td>
              <td style={tdClass}>
                <strong>Type</strong>
              </td>
              <td style={tdClass}>
                <strong>MOC</strong>
              </td>
              <td style={tdClass}>
                <strong>Certificate</strong>
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Element skin Sensors</strong>
              </td>
              <td style={tdClass}>
                {formik?.values?.sensor[0]?.quantity || "N/A"}
              </td>
              <td style={tdClass}>
                {formik?.values?.sensor[0]?.sensorsubtype || "N/A"}
              </td>
              <td style={tdClass}>{formik?.values?.sensor[0]?.material}</td>
              <td style={tdClass}>
                {Array.isArray(formik?.values?.certificationType)
                  ? formik?.values?.certificationType?.map((type, index) => (
                      <span key={index}>
                        {type.value}
                        {/* Replace 'name' with the appropriate property */}
                        {index < formik?.values?.certificationType.length - 1 &&
                          ", "}
                      </span>
                    ))
                  : formik?.values?.certificationType || "N/A"}
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Tube Sheet Sensors</strong>
              </td>
              <td style={tdClass}>
                {formik?.values?.sensor[1]?.quantity || "N/A"}
              </td>
              <td style={tdClass}>
                {formik?.values?.sensor[1]?.sensorsubtype || "N/A"}
              </td>
              <td style={tdClass}>
                {formik?.values?.sensor[1]?.material || "N/A"}
              </td>
              <td style={tdClass}>
                {Array.isArray(formik?.values?.certificationType)
                  ? formik?.values?.certificationType?.map((type, index) => (
                      <span key={index}>
                        {type.value}
                        {/* Replace 'name' with the appropriate property */}
                        {index < formik?.values?.certificationType.length - 1 &&
                          ", "}
                      </span>
                    ))
                  : formik?.values?.certificationType || "N/A"}
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Process Temp. Sensor</strong>
              </td>
              <td style={tdClass}>
                {formik?.values?.sensor[2]?.quantity || "N/A"}
              </td>
              <td style={tdClass}>
                {formik?.values?.sensor[2]?.sensorsubtype || "N/A"}
              </td>
              <td style={tdClass}>{formik?.values?.sensor[2]?.material}</td>
              <td style={tdClass}>
                {Array.isArray(formik?.values?.certificationType)
                  ? formik?.values?.certificationType?.map((type, index) => (
                      <span key={index}>
                        {type.value}
                        {/* Replace 'name' with the appropriate property */}
                        {index < formik?.values?.certificationType.length - 1 &&
                          ", "}
                      </span>
                    ))
                  : formik?.values?.certificationType || "N/A"}
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Temp Transmitter - Element skin</strong>
              </td>
              <td style={tdClass}>
                {formik?.values?.sensor[0]?.quantity || "N/A"}
              </td>
              <td style={tdClass} colSpan={2}>
                {formik?.values?.temptransmitter || "N/A"}
              </td>
              <td style={tdClass}>
                {Array.isArray(formik?.values?.certificationType)
                  ? formik?.values?.certificationType?.map((type, index) => (
                      <span key={index}>
                        {type.value}
                        {/* Replace 'name' with the appropriate property */}
                        {index < formik?.values?.certificationType.length - 1 &&
                          ", "}
                      </span>
                    ))
                  : formik?.values?.certificationType || "N/A"}
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Temp Transmitter - Tubesheet</strong>
              </td>
              <td style={tdClass}>
                {formik?.values?.sensor[1]?.quantity || "N/A"}
              </td>
              <td style={tdClass} colSpan={2}>
                {formik?.values?.temptransmitter || "N/A"}
              </td>
              <td style={tdClass}>
                {Array.isArray(formik?.values?.certificationType)
                  ? formik?.values?.certificationType?.map((type, index) => (
                      <span key={index}>
                        {type.value}
                        {/* Replace 'name' with the appropriate property */}
                        {index < formik?.values?.certificationType.length - 1 &&
                          ", "}
                      </span>
                    ))
                  : formik?.values?.certificationType || "N/A"}
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Temp Transmitter -Process</strong>
              </td>
              <td style={tdClass}>
                {formik?.values?.sensor[2]?.quantity || "N/A"}
              </td>
              <td style={tdClass} colSpan={2}>
                {formik?.values?.temptransmitter || "N/A"}
              </td>
              <td style={tdClass}>
                {Array.isArray(formik?.values?.certificationType)
                  ? formik?.values?.certificationType?.map((type, index) => (
                      <span key={index}>
                        {type.value}
                        {/* Replace 'name' with the appropriate property */}
                        {index < formik?.values?.certificationType.length - 1 &&
                          ", "}
                      </span>
                    ))
                  : formik?.values?.certificationType || "N/A"}
              </td>
            </tr>
          </table>
          <table style={tableClass}>
            <tr style={trClass}>
              <td colSpan="3" bgcolor="#CCCCCC" style={tdClass}>
                <div align="center">
                  <strong>HEAT TRANSFER DATA</strong>
                </div>
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Total Surface Area</strong>
              </td>
              <td style={tdClass}>
                {formik?.values?.totalsurfacearea || "N/A"}
              </td>
              <td style={tdClass}>m2</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Heat Transfer Co-Eff</strong>
              </td>
              <td style={tdClass}>
                {formik?.values?.heatTransferCoefficient || "N/A"}
              </td>
              <td style={tdClass}>m2</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>LMTD</strong>
              </td>
              <td style={tdClass}>
                {350 - parseFloat(formik?.values?.outletTemp) || "N/A"}
              </td>
              <td style={tdClass}>Deg °C</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Ambient Temperature</strong>
              </td>
              <td style={tdClass}>
                {formik?.values?.ambienttemperature || "N/A"}
              </td>
              <td style={tdClass}>Deg °C</td>
            </tr>
          </table>
        </div>
        <div style={tableParentDiv}>
          <table style={tableClass}>
            <tr style={trClass}>
              <td colSpan="5" bgcolor="#CCCCCC" style={tdClass}>
                <div align="center">
                  <strong>DESIGN STANDARDS &amp; CERTIFICATIONS</strong>
                </div>
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Design</strong>
              </td>
              <td style={tdClass}>{formik?.values?.standard || "N/A"}</td>
            </tr>
            {formik?.values?.heaterterminalbox === "yes" && (
              <tr style={trClass}>
                <td style={tdClass}>
                  <strong>Heater Terminal Box Certificate</strong>
                </td>
                <td style={tdClass}>
                  {formik?.values?.heaterterminalboxcertificationrequired || ""}
                  &nbsp; {formik?.values?.heaterterminalboxijb || " "} &nbsp;
                  {formik?.values?.heaterterminalboxzone || ""} &nbsp;
                  {formik?.values?.heaterterminalboxgasgroup || ""}&nbsp;
                  {formik?.values?.heaterterminalboxtempcode || ""} &nbsp;
                  {formik?.values?.heaterterminalboxiprating || ""}
                </td>
              </tr>
            )}
            {formik?.values?.instrument === "yes" && (
              <tr style={trClass}>
                <td style={tdClass}>
                  <strong>Instrument Junction Box Certificate</strong>
                </td>
                <td style={tdClass}>
                  {formik?.values?.certificationrequired || ""} &nbsp;
                  {formik?.values?.ijb || " "} &nbsp;
                  {formik?.values?.zone || ""} &nbsp;
                  {formik?.values?.gasgroup || ""}&nbsp;
                  {formik?.values?.tempCode || ""} &nbsp;
                  {formik?.values?.ipRating || ""}
                </td>
              </tr>
            )}
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Zone</strong>
              </td>
              <td style={tdClass}>{formik?.values?.zone || "N/A"}</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Gas group</strong>
              </td>
              <td style={tdClass}>{formik?.values?.gasgroup || "N/A"}</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>
                  Temperature classification
                  <br />
                  (Hazards will not ignite below)
                </strong>
              </td>
              <td style={tdClass}>{formik?.values?.heaterterminalboxtempcode || "N/A"}</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>IP Rating</strong>
              </td>
              <td style={tdClass}>{formik?.values?.ipRating || "N/A"}</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Other Certification -I</strong>
              </td>
              <td style={tdClass}>{formik?.values?.speccertification || "N/A"}</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}>
                <strong>Other Certification -II</strong>
              </td>
              <td style={tdClass}>Not Applicable</td>
            </tr>
          </table>
          <table style={{ ...tableClass, border: "1px solid black" }}>
            <tr style={trClass}>
              <td bgcolor="#CCCCCC" style={tdClass} colSpan={8}>
                <div align="center">
                  <strong>Performance partner</strong>
                </div>
              </td>
            </tr>

            <tr style={{ height: "20px" }}>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td
                rowSpan="6"
                colSpan="4"
                style={{
                  border: "1px solid black",
                  width: "300px",
                  height: "70px",
                }}
              ></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td align="left" style={{ paddingLeft: "15px" }}>
  
                  <strong>
                    P in bar ({formik?.values?.allowedPressureDropUnit || "N/A"}
                    )
                  </strong>
                <br />
                <span style={{ fontSize: "12px" }}>
                  {formik?.values?.inletPressure || "N/A"}
                </span>
              </td>
              <td align="left" style={{ paddingLeft: "" }}>
                <strong>T in °{formik?.values?.unit || "N/A"}</strong>
                <br />
                <span style={{ fontSize: "12px" }}>
                  {formik?.values?.startingTemp || "N/A"}
                </span>
              </td>
              <td align="right" style={{ paddingRight: "15px" }}>
                <strong>T out °{formik?.values?.unit || "N/A"}</strong>
                <br />
                <span style={{ fontSize: "12px" }}>
                  {formik?.values?.outletTemp || "N/A"}
                </span>
              </td>
              <td align="right" style={{ paddingRight: "15px" }}>
      
                  <strong>
                    P out bar (
                    {formik?.values?.allowedPressureDropUnit || "N/A"})
                  </strong>
                <br />
                <span style={{ fontSize: "12px" }}>
                  {formik?.values?.outletPressure || "N/A"}
                </span>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan={2}
                style={{
                  
                  fontSize: "12px",
                }}
              >
                &nbsp;Press. Drop.- &nbsp;{formik?.values?.allowedPressureDrop || "N/A"} &nbsp; {formik?.values?.allowedPressureDropUnit || "N/A"}
              </td>
              
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td align="right" style={{ fontSize: "12px" }}>Sheath Temp.°C:
                &nbsp;{parseInt(formik?.values?.sheathtemp || "N/A")}&nbsp;
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
}

export default HeaterBundleWithVessel;
