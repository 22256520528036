import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useEdges } from "react-flow-renderer";

function BomFormula({ formik }) {
  let J4 = formik?.values?.FlangeClass;
  let J5 = formik?.values?.tubesheetsize;

  const AA = {
    61: 7.7,
    62: 11.8,
    63: 20.4,
    64: 31.8,
    65: 49.9,
    66: 63.9,
    67: 81.8,
    68: 99.8,
    69: 129,
    70: 195,
    71: 306,
    72: 363,
    73: 430,
    74: 537,
    75: 600,
    76: 730,
    77: 793,
    78: 893,
  };

  const AB = {
    61: 12.2,
    62: 32.7,
    63: 36.7,
    64: 56.7,
    65: 85.9,
    66: 113,
    67: 134,
    68: 179,
    69: 229,
    70: 398,
    71: 460,
    72: 566,
    73: 663,
    74: 770,
    75: 894,
    76: 1041,
    77: 872,
    78: 1035,
  };

  const AC = {
    61: 5.9,
    62: 8.6,
    63: 13.6,
    64: 19.5,
    65: 29,
    66: 41,
    67: 44.5,
    68: 59,
    69: 75,
    70: 99.8,
    71: 125,
    72: 160,
    73: 195,
    74: 245,
    75: 305,
    76: 365,
    77: 435,
    78: 500,
  };

  const AD = {
    61: 10,
    62: 17.7,
    63: 26.3,
    64: 36.7,
    65: 52.2,
    66: 74.6,
    67: 86.2,
    68: 113,
    69: 148,
    70: 215,
    71: 275,
    72: 340,
    73: 425,
    74: 500,
    75: 580,
    76: 670,
    77: 770,
    78: 870,
  };

  function calculateValue(J4, J5, AA, AB) {
    function choose(index, values) {
      return index >= 0 && index < values.length ? values[index] : 0;
    }

    let firstTerm, secondTerm;
    const AAValues = [
      AA[61],
      AA[62],
      AA[63],
      AA[64],
      AA[65],
      AA[66],
      AA[67],
      AA[68],
      AA[69],
      AA[70],
      AA[71],
      AA[72],
      AA[73],
      AA[74],
      AA[75],
      AA[76],
      AA[77],
      AA[78],
    ];
    const ABValues = [
      AB[61],
      AB[62],
      AB[63],
      AB[64],
      AB[65],
      AB[66],
      AB[67],
      AB[68],
      AB[69],
      AB[70],
      AB[71],
      AB[72],
      AB[73],
      AB[74],
      AB[75],
      AB[76],
      AB[77],
      AB[78],
    ];

    if (J5 > 24) {
      firstTerm = choose(J5 - 25, AAValues.slice(10));
      secondTerm = choose(J5 - 25, ABValues.slice(10));
    } else {
      firstTerm = choose(J5 - 1, AAValues.slice(0, 10));
      secondTerm = choose(J5 - 1, ABValues.slice(0, 10));
    }

    const result = (2 - J4 / 150) * firstTerm + (J4 / 150 - 1) * secondTerm;
    return result;
  }

  function s_no_05_net_wt(J4, J5, AC, AD) {
    const factor1 = 2 - J4 / 150;
    const factor2 = J4 / 150 - 1;

    function getValue(array, j5) {
      if (j5 > 24) {
        const index = j5 - 25;
        return array[`AC${71 + index}`] || 0;
      } else {
        return array[`AC${61 + j5}`] || 0;
      }
    }

    const acValue = factor1 * getValue(AC, J5);
    const adValue = factor2 * getValue(AD, J5);

    return acValue + adValue;
  }

  useEffect(() => {
    const AAAB = calculateValue(J4, J5, AA, AB);
    const ACAD = s_no_05_net_wt(J4, J5, AC, AD);

  });
  return <div></div>;
}

export default BomFormula;
