const vesselSelection = {
  cp_controlPanel: "yes",
  cp_areaClassification: "",
  // cp_Power:"",
  // cp_voltage:"",
  // cp_frequency:"",
  cp_breakingCapacity: "",
  cp_ipRating: "IP-54",
  cp_noOfBanks: "",
  cp_thyristorPanelSelection: "",
  cp_thyristorPanel: "",
  cp_enclosureType: "",
  cp_typeOfControlling: "",
  cp_inputTypeOfController: "",
  cp_quantity: "",
  cp_incomerBusDuct: "", // Incomer Bus Duct (Yes / No)
  cp_controlPanelQuantity: "", // Control panel Quantity
  cp_controlPanelMOC: "", // Control panel MOC
  cp_cableEntry: "", // Cable Entry (Top / Bottom)
  cp_skinTemperatureController: "", // Skin Temperature Controller
  cp_processTemperatureController: "", // Process Temperature Controller
  cp_tubeSheetTemperatureController: "", // Tube Sheet Temperature Controller
  cp_busBarMaterial: "Copper", // Bus Bar Material
  cp_typeTestRequirement: "", // Type Test Requirement (Yes/No)
  cp_thyristorLag: "", // Thyristor Lag
  cp_thyristorFiringType: "Burst Firing", // Thyristor Firing Type
  cp_packingType: "Wooden", // Packing Type
  cp_enclosureMake: "", // Enclosure Make
  cp_enclosureSizeLength: "", // Enclosure Size (length)
  cp_enclosureSizeWidth: "", // Enclosure Size (width)
  cp_enclosureSizeHeight: "", // Enclosure Size (height)
  cp_enclosureCompartment: "", // Enclosure Compartment
  cp_enclosureMaterial: "", // Enclosure Material
  cp_softStart: "", // Soft Start (Yes/No)
  cp_controlSupplyVoltage: "240 A.C", // Control Supply Voltage
  cp_sil22Requirement: "", // S.I.L 22 Requirement (Yes/No)
};

export default vesselSelection;