import React, { useEffect, useState } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
function Screen4({ formik, isPageComplete, handleFlangeThickness }) {
  const [totalCount, setTotalCount] = useState(9);
  const [actualCount, setActualCount] = useState(0);
  const [baffleODValue, setBaffleODValue] = useState(null);
  const [toastShown, setToastShown] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const [innerDiameter, setInnerDiameter] = useState("");
  // const [flangeSizeOptions, setFlangeSizeOptions] = useState([]);
  const [calcData, setCalcData] = useState({
    innerDiameter: "",
    outerTubeLimitDiameter: "",
    thirtyt: "",
    fortyfives: "",
    sixtyt: "",
    ninetys: "",
  });
  const [materials, setMaterials] = useState([]);
  const [materialCode, setMaterialCode] = useState([]);
  const [materialCodeMapping, setMaterialCodeMapping] = useState({});

  const handleInputValue = (value, count) => {
    if (value === "") {
      return count > 0 ? count - 1 : count;
    }
    if (value === undefined) {
      return count + 0;
    }
    if (value !== undefined) {
      return count + 1;
    }
  };
  useEffect(() => {
    let count = 0;
    let total_count = totalCount;
    count = handleInputValue(formik?.values?.designTemperature, count);
    count = handleInputValue(formik?.values?.designPressure, count);
    count = handleInputValue(formik?.values?.elementDiameter, count);
    count = handleInputValue(formik?.values?.flangeSelection, count);
    count = handleInputValue(formik?.values?.FlangeMaterial, count);
    count = handleInputValue(formik?.values?.FlangeMaterialCode, count);
    count = handleInputValue(formik?.values?.FlangeClass, count);
    count = handleInputValue(formik?.values?.flangeSize, count);
    count = handleInputValue(formik?.values?.schedule, count);
    // count = handleInputValue(formik?.values?.maxPressure, count);
    setActualCount(count);
  }, [formik.values]);

  useEffect(() => {
    isPageComplete(4, totalCount, actualCount);
  }, [actualCount, totalCount]);

  const flangeSizeOptions = [
    { value: "0.125", label: "1/8" },
    { value: "0.25", label: "1/4" },
    { value: "0.375", label: "3/8" },
    { value: "0.5", label: "1/2" },
    { value: "0.75", label: "3/4" },
    { value: "1", label: "1" },
    { value: "1.25", label: "1-1/4" },
    { value: "1.5", label: "1-1/2" },
    { value: "2", label: "2" },
    { value: "2.5", label: "2-1/2" },
    { value: "3", label: "3" },
    { value: "3.5", label: "3-1/2" },
    { value: "4", label: "4" },
    { value: "4", label: "4-1/2" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "14", label: "14" },
    { value: "16", label: "16" },
    { value: "18", label: "18" },
    { value: "20", label: "20" },
    { value: "24", label: "24" },
    { value: "26", label: "26" },
    { value: "28", label: "28" },
    { value: "30", label: "30" },
    { value: "32", label: "32" },
    { value: "34", label: "34" },
    { value: "36", label: "36" },
    { value: "42", label: "42" },
    { value: "48", label: "48" },
  ];
  const handleShellThickness = async (flangeSize, shellPipeSch) => {
    try {
      const response = await axiosPrivate.post("/get/shell-thickness", {
        flange_size: flangeSize,
        shell_pipe_sch: shellPipeSch,
      });

      if (response.status === 200) {
        const thickness = response.data?.data[0]?.shell_thickness;
        formik.setFieldValue("shellThickness", thickness);

        if (response.data.code === 404) {
          showErrorToast("No Shell Thickness Found");
        }
      }
    } catch (error) {
      console.error("Error fetching shell thickness:", error);
    }
  };

  useEffect(() => {
    const { flangeSize, schedule } = formik.values;
    if (flangeSize && schedule) {
      handleShellThickness(flangeSize, schedule);
    }
  }, [formik.values.flangeSize, formik.values.schedule]);

  useEffect(() => {
    if (formik?.values?.mdmt === "0" || formik?.values?.mdmt == "") {
      formik.setFieldValue("designTemperaturemin", -29);
    } else if (formik?.values?.mdmt) {
      formik.setFieldValue("designTemperaturemin", formik?.values?.mdmt);
    }
  }, [formik?.values?.mdmt]);

  useEffect(() => {
    const fetchSCH = async () => {
      try {
        const response = await axiosPrivate.get("schedules");
        if (response.status == 200) {
          const data = response?.data?.data;
          if (data.length > 0) {
            const uniqueSchedules = Array.from(
              new Set(data.map((item) => item.sch))
            ).map((sch) => data.find((item) => item.sch === sch));
            setSchedules(uniqueSchedules);

            const uniqueFlangeSizes = [
              ...new Set(data.map((item) => item.flange_size)),
            ];
            if (uniqueSchedules.length > 0) {
              const options = uniqueFlangeSizes.map((size) => ({
                value: size,
                label: size,
              }));
              // setFlangeSizeOptions(options);
            }
          }
        }
      } catch (error) {
        showErrorToast(error.message);
      }
    };

    const fetchMaterials = async () => {
      try {
        const response = await axiosPrivate.get("/material-grade");
        if (response.status == 200) {
          const data = response?.data?.data;

          if (data.length > 0) {
            const materialCodeMapping = data.reduce((acc, item) => {
              if (!acc[item.materials]) {
                acc[item.materials] = new Set();
              }
              acc[item.materials].add(item.materials_code);
              return acc;
            }, {});

            const uniqueMaterials = Array.from(
              new Set(data.map((item) => item.materials))
            );
            if (uniqueMaterials.length > 0) {
              const materialsOptions = uniqueMaterials.map((material) => ({
                value: material,
                label: material,
              }));
              setMaterials(materialsOptions);
              setMaterialCodeMapping(materialCodeMapping);
            }
          } else {
            setMaterials([]);
            setMaterialCodeMapping([]);
          }
        }
      } catch (error) {
        showErrorToast(error.message);
      }
    };

    fetchSCH();
    fetchMaterials();
  }, []);

  const handleMaterialSelect = (selectedMaterial) => {
    const materialCodes = Array.from(
      materialCodeMapping[selectedMaterial.value] || []
    ).map((materialCode) => ({
      value: materialCode,
      label: materialCode,
    }));
    setMaterialCode(materialCodes);
  };

  const fetchMaterialGrade = async () => {
    try {
      const data = {
        material: formik.values.FlangeMaterial,
        material_code: formik.values.FlangeMaterialCode,
      };

      const response = await axiosPrivate.post(
        "/material-grade/get-values",
        data
      );
      if (response.status == 200) {
        const responseData = response.data.data;
        formik.setFieldValue("MaterialGrade", responseData[0].materials_grade);
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  const getData = async (e) => {
    e.preventDefault();
    try {
      // Assuming you have formik or some other way to get these values
      const data = {
        flange_size: formik.values.flangeSize,
        element_diameter_mm: formik.values.heatersheathtubedia,
        schedule: formik.values.schedule,
      };

      const response = await axiosPrivate.post("/flange-size/get-values", data);
      if (response.status == 200) {
        const responseData = response.data.data[0]; // Assuming you want the first item in the data array
        formik.setValues({
          ...formik.values,
          innerDiameter: responseData.inner_diameter_mm,
          outerTubeLimitDiameter: responseData.outertube_diameter_mm,
          thirtyt: responseData["30T"],
          fortyfives: responseData["45S"],
          sixtyt: responseData["60T"],
          ninetys: responseData["90S"],
        });
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  // Define the variables
  const J5 = formik.values.tubesheetsize;
  const shellThickness = parseFloat(formik.values.shellThickness);
  const numberOfBaffles = parseFloat(formik.values.numberofbaffles);
  const bafflePlateThickness = parseFloat(formik.values.baffleplatethickness);

  // Define the arrays for CHOOSE function equivalents
  const choose1Values = [660, 710, 760, 810, 860, 910, 960, 1010];
  const choose2Values = [
    33.4, 48.3, 60.3, 73, 88.9, 102, 114, 168, 219, 273, 324, 356, 406, 457,
    508, 610,
  ];

  // Function to get the CHOOSE value based on the conditions
  const chooseValue = (J5) => {
    if (J5 > 24) {
      return choose1Values[J5 - 25];
    } else {
      let index;
      switch (J5) {
        case 1.5:
          index = 5;
          break;
        case 2.5:
          index = 7;
          break;
        case 3.5:
          index = 9;
          break;
        default:
          index = J5;
      }
      return choose2Values[index - 1]; // Adjusting for zero-based index
    }
  };

  // Calculate the adjusted value
  const calculateBaffleWeight = () => {
    const value = chooseValue(J5);
    const adjustedValue = value - 10 - 2 * shellThickness;
    const squarevalue = Math.pow(adjustedValue, 2);
    // Calculate the final result
    const finalResult =
      ((squarevalue * bafflePlateThickness * numberOfBaffles * 8) /
        1000000 /
        4) *
      3.14;
    formik.setFieldValue("baffleWeight", finalResult);
  };

  const calculateBaffleOD = () => {
    const shellThickness = parseFloat(formik?.values?.shellThickness);
    const baffleWeight = parseFloat(formik.values.baffleWeight);
    const numOfBafflesNum = parseFloat(formik?.values?.numberofbaffles);
    const baffleThicknessNum = parseFloat(formik?.values?.baffleplatethickness);
    const innerDiameter = parseFloat(formik?.values?.innerDiameter);
    const baffleod = innerDiameter - 6;
    if (isNaN(innerDiameter) || innerDiameter === 0) {
      formik?.setFieldValue("baffleOD", "");
    } else if (baffleod < 0) {
      formik?.setFieldValue("baffleOD", "");
    } else {
      formik?.setFieldValue("baffleOD", baffleod);
    }
    // if (!isNaN(baffleWeight) && !isNaN(numOfBafflesNum) && !isNaN(baffleThicknessNum)) {
    //   const result = Math.sqrt(
    //     (4 * baffleWeight * 1000000) /
    //     (8 * numOfBafflesNum * baffleThicknessNum * 3.14)
    //   );
    // setBaffleODValue(result);
  };

  const baffleHolesOD = () => {
    const heatersHeatTubeDia = parseFloat(formik?.values?.heatersheathtubedia);
    const baffleholdeod = heatersHeatTubeDia + 0.5;

    formik?.setFieldValue("baffleOD", innerDiameter - 6);
    formik.setFieldValue("baffleHolesOD", baffleholdeod);

    const baffleod = parseFloat(formik?.values?.baffleOD);
    const lastHoleCtoCalculated = baffleod - baffleholdeod - 6;
    if (isNaN(baffleod) || baffleod <= 0) {
      formik.setFieldValue("lastHoleCtoC", "");
    } else {
      formik.setFieldValue("lastHoleCtoC", lastHoleCtoCalculated);
    }

    if (isNaN(heatersHeatTubeDia) || heatersHeatTubeDia <= 0) {
      formik.setFieldValue("baffleHolesOD", "");
    }
  };

  const centreline = (lastHoleCtoC, pitch) => {
    if (isNaN(lastHoleCtoC) || isNaN(pitch) || pitch === 0) {
      formik.setFieldValue("centreLine", 0);
      return;
    }

    const significance = 2;
    const tempValue = lastHoleCtoC / (2 * pitch);
    const floorValue = Math.floor(tempValue / significance) * significance;
    const result = floorValue * 2 + 1;
    formik.setFieldValue("centreLine", result);
  };

  const firstline = (lastHoleCtoC, pitch) => {
    if (
      isNaN(lastHoleCtoC) ||
      isNaN(pitch) ||
      pitch === 0 ||
      lastHoleCtoC / 2 <= pitch * 0.707
    ) {
      formik.setFieldValue("firstLine", 0);
      return;
    }

    const halfLastHoleCtoC = lastHoleCtoC / 2;
    const pitchMult = pitch * 0.707;
    const sqrtTerm = Math.sqrt(
      halfLastHoleCtoC * halfLastHoleCtoC - pitchMult * pitchMult
    );
    const intermediateValue = (sqrtTerm - pitch / 2) / pitch;
    const floorValue = Math.floor(intermediateValue / 2) * 2; // Apply floor with significance 2
    const firstlineCalculation = (floorValue + 1) * 2;
    formik.setFieldValue("firstLine", firstlineCalculation);
  };

  const secondline = (lastHoleCtoC, pitch) => {
    if (
      isNaN(lastHoleCtoC) ||
      isNaN(pitch) ||
      pitch === 0 ||
      lastHoleCtoC / 2 <= 2 * pitch * 0.707
    ) {
      formik.setFieldValue("secondLine", 0);
      return;
    }

    const sqrtTerm = Math.sqrt(
      (lastHoleCtoC / 2) * (lastHoleCtoC / 2) -
        2 * pitch * 0.707 * (2 * pitch * 0.707)
    );
    if (!isNaN(sqrtTerm) && isFinite(sqrtTerm)) {
      const floorTerm = Math.floor(sqrtTerm / pitch);
      const significance = Math.floor(floorTerm / 2) * 2;
      const secondlineCalculation = significance * 2 + 1;
      formik.setFieldValue("secondLine", secondlineCalculation);
    } else {
      formik.setFieldValue("secondLine", 0);
    }
  };

  const thirdline = (lastHoleCtoC, pitch) => {
    if (
      isNaN(lastHoleCtoC) ||
      isNaN(pitch) ||
      pitch === 0 ||
      lastHoleCtoC / 2 <= 3 * pitch * 0.707
    ) {
      formik.setFieldValue("thirdLine", 0);
      return;
    }

    const sqrtTerm =
      Math.sqrt(
        (lastHoleCtoC / 2) * (lastHoleCtoC / 2) -
          3 * pitch * 0.707 * (3 * pitch * 0.707)
      ) -
      pitch / 2;
    if (!isNaN(sqrtTerm) && isFinite(sqrtTerm)) {
      const floorTerm = Math.floor(sqrtTerm / pitch);
      const significance = Math.floor(floorTerm / 2) * 2;
      const thirdlineCalculation = (significance + 1) * 2;
      formik.setFieldValue("thirdLine", thirdlineCalculation);
    } else {
      formik.setFieldValue("thirdLine", 0);
    }
  };

  const fourthline = (lastHoleCtoC, pitch) => {
    if (
      isNaN(lastHoleCtoC) ||
      isNaN(pitch) ||
      pitch === 0 ||
      lastHoleCtoC / 2 <= 4 * pitch * 0.707
    ) {
      formik.setFieldValue("fourthLine", 0);
      return;
    }

    const sqrtTerm = Math.sqrt(
      (lastHoleCtoC / 2) * (lastHoleCtoC / 2) -
        4 * pitch * 0.707 * (4 * pitch * 0.707)
    );
    if (!isNaN(sqrtTerm) && isFinite(sqrtTerm)) {
      const floor = Math.floor(sqrtTerm / pitch);
      const significance = Math.floor(floor / 2) * 2;
      const fourthlineCalculation = significance * 2 + 1;
      formik.setFieldValue("fourthLine", fourthlineCalculation);
    } else {
      formik.setFieldValue("fourthLine", 0);
    }
  };

  const fifthline = (lastHoleCtoC, pitch) => {
    if (
      isNaN(lastHoleCtoC) ||
      isNaN(pitch) ||
      pitch === 0 ||
      lastHoleCtoC / 2 <= 5 * pitch * 0.707
    ) {
      formik.setFieldValue("fifthLine", 0);
      return;
    }

    const sqrtTerm =
      Math.sqrt(
        (lastHoleCtoC / 2) * (lastHoleCtoC / 2) -
          5 * pitch * 0.707 * (5 * pitch * 0.707)
      ) -
      pitch / 2;
    if (!isNaN(sqrtTerm) && isFinite(sqrtTerm)) {
      const floorTerm = Math.floor(sqrtTerm / pitch);
      const significance = Math.floor(floorTerm / 2) * 2;
      const fifthlineCalculation = (significance + 1) * 2;
      formik.setFieldValue("fifthLine", fifthlineCalculation);
    } else {
      formik.setFieldValue("fifthLine", 0);
    }
  };

  const sixthline = (lastHoleCtoC, pitch) => {
    if (lastHoleCtoC / 2 > 6 * pitch * 0.707) {
      const sqrtTerm = Math.sqrt(
        (lastHoleCtoC / 2) * (lastHoleCtoC / 2) -
          6 * pitch * 0.707 * (6 * pitch * 0.707)
      );
      if (!isNaN(sqrtTerm) && isFinite(sqrtTerm)) {
        const floor = Math.floor(sqrtTerm / pitch);
        const significance = Math.floor(floor / 2) * 2;
        const sixthlineCalculation = significance * 2 + 1;
        formik.setFieldValue("sixthLine", sixthlineCalculation);
      } else {
        formik.setFieldValue("sixthLine", "");
      }
    } else {
      formik.setFieldValue("sixthLine", "");
    }
  };

  const seventhline = (lastHoleCtoC, pitch) => {
    if (lastHoleCtoC / 2 > 7 * pitch * 0.707) {
      const halfLastHoleCtoC = lastHoleCtoC / 2;
      const pitchMult = 7 * pitch * 0.707;
      const sqrtTerm = Math.sqrt(
        halfLastHoleCtoC * halfLastHoleCtoC - pitchMult * pitchMult
      );
      if (!isNaN(sqrtTerm) && isFinite(sqrtTerm)) {
        const intermediateValue = (sqrtTerm - pitch / 2) / pitch;
        const floorValue = Math.floor(intermediateValue / 2) * 2;
        const seventhlineCalculation = (floorValue + 1) * 2;
        formik.setFieldValue("seventhLine", seventhlineCalculation);
      } else {
        formik.setFieldValue("seventhLine", "0");
      }
    } else {
      formik.setFieldValue("seventhLine", "0");
    }
  };

  const eigthline = (lastHoleCtoC, pitch) => {
    if (lastHoleCtoC / 2 > 8 * pitch * 0.707) {
      const sqrtTerm = Math.sqrt(
        (lastHoleCtoC / 2) * (lastHoleCtoC / 2) -
          8 * pitch * 0.707 * (8 * pitch * 0.707)
      );
      if (!isNaN(sqrtTerm) && isFinite(sqrtTerm)) {
        const floorTerm = Math.floor(sqrtTerm / pitch);
        const significance = Math.floor(floorTerm / 2) * 2;
        const eigthlineCalculation = significance * 2 + 1;
        formik.setFieldValue("eighthLine", eigthlineCalculation);
      } else {
        formik.setFieldValue("eighthLine", "0");
      }
    } else {
      formik.setFieldValue("eighthLine", "0");
    }
  };

  const ninethline = (lastHoleCtoC, pitch) => {
    if (lastHoleCtoC / 2 > 9 * pitch * 0.707) {
      const sqrtTerm =
        Math.sqrt(
          (lastHoleCtoC / 2) * (lastHoleCtoC / 2) -
            9 * pitch * 0.707 * (9 * pitch * 0.707)
        ) -
        pitch / 2;
      if (!isNaN(sqrtTerm) && isFinite(sqrtTerm)) {
        const floorTerm = Math.floor(sqrtTerm / pitch);
        const significance = Math.floor(floorTerm / 2) * 2;
        const ninethlineCalculation = (significance + 1) * 2;
        formik.setFieldValue("ninethLine", ninethlineCalculation);
      } else {
        formik.setFieldValue("ninethLine", "0");
      }
    } else {
      formik.setFieldValue("ninethLine", "0");
    }
  };

  const tenthline = (lastHoleCtoC, pitch) => {
    if (lastHoleCtoC / 2 > 10 * pitch * 0.707) {
      // Calculate sqrtTerm
      const sqrtTerm = Math.sqrt(
        (lastHoleCtoC / 2) * (lastHoleCtoC / 2) -
          10 * pitch * 0.707 * (10 * pitch * 0.707)
      );
      if (!isNaN(sqrtTerm) && isFinite(sqrtTerm)) {
        const floor = Math.floor(sqrtTerm / pitch);
        const significance = Math.floor(floor / 2) * 2;
        const tenthlineCalculation = significance * 2 + 1;
        // Set the calculated value in Formik field 'tenthLine'
        formik.setFieldValue("tenthLine", tenthlineCalculation);
      } else {
        // If the condition is not met, set 'tenthLine' to "0" or handle accordingly
        formik.setFieldValue("tenthLine", "0");
      }
    } else {
      // If the condition is not met, set 'tenthLine' to "0" or handle accordingly
      formik.setFieldValue("tenthLine", "0");
    }
  };

  const eleventhline = (lastHoleCtoC, pitch) => {
    if (lastHoleCtoC / 2 > 11 * pitch * 0.707) {
      // Calculate sqrtTerm
      const sqrtTerm =
        Math.sqrt(
          (lastHoleCtoC / 2) * (lastHoleCtoC / 2) -
            11 * pitch * 0.707 * (11 * pitch * 0.707)
        ) -
        pitch / 2;

      // Check if sqrtTerm is a valid number
      if (!isNaN(sqrtTerm) && isFinite(sqrtTerm)) {
        // Calculate floorTerm
        const floorTerm = Math.floor(sqrtTerm / pitch);

        // Calculate significance
        const significance = Math.floor(floorTerm / 2) * 2;

        // Apply the formula: (floorTerm + 1) * 2
        const eleventhlineCalculation = (significance + 1) * 2;

        // Set the calculated value in Formik field 'eleventhLine'
        formik.setFieldValue("eleventhLine", eleventhlineCalculation);
      } else {
        // If sqrtTerm is NaN or Infinity, set 'eleventhLine' to "0" or handle accordingly
        formik.setFieldValue("eleventhLine", "0");
      }
    } else {
      // If the condition is not met, set 'eleventhLine' to "0" or handle accordingly
      formik.setFieldValue("eleventhLine", "0");
    }
  };

  const twelvethline = (lastHoleCtoC, pitch) => {
    if (lastHoleCtoC / 2 > 12 * pitch * 0.707) {
      // Calculate sqrtTerm
      const sqrtTerm = Math.sqrt(
        (lastHoleCtoC / 2) * (lastHoleCtoC / 2) -
          12 * pitch * 0.707 * (12 * pitch * 0.707)
      );
      if (!isNaN(sqrtTerm) && isFinite(sqrtTerm)) {
        const floor = Math.floor(sqrtTerm / pitch);
        const significance = Math.floor(floor / 2) * 2;
        const twelvethlineCalculation = significance * 2 + 1;
        // Set the calculated value in Formik field 'twelvethLine'
        formik.setFieldValue("twelvethLine", twelvethlineCalculation);
      } else {
        // If the condition is not met, set 'twelvethLine' to "0" or handle accordingly
        formik.setFieldValue("twelvethLine", "0");
      }
    } else {
      // If the condition is not met, set 'twelvethLine' to "0" or handle accordingly
      formik.setFieldValue("twelvethLine", "0");
    }
  };

  useEffect(() => {
    const { FlangeMaterial, FlangeClass, designTemperature, MaterialGrade } =
      formik.values;

    if (FlangeMaterial && FlangeClass && designTemperature && MaterialGrade) {
      const debounceTimeout = setTimeout(() => {
        const calculatemaxpressure = async () => {
          const temperature = designTemperature <= 38 ? 38 : designTemperature;
          try {
            const response = await axiosPrivate.post("/get/max-pressure", {
              material: FlangeMaterial,
              class: FlangeClass,
              temperature: temperature,
              materialgrade: MaterialGrade,
            });

            const maxPressure = response?.data?.data;
            formik.setFieldValue("maxPressure", maxPressure);
          } catch (error) {
            showErrorToast("The values do not match any max pressure");
          }
        };
        calculatemaxpressure();
      }, 500);

      return () => {
        clearTimeout(debounceTimeout);
      };
    }
  }, [
    formik.values.FlangeMaterial,
    formik.values.FlangeClass,
    formik.values.designTemperature,
    formik.values.MaterialGrade,
  ]);

  const totalHoles = () => {
    // Retrieve values from formik
    const firstLine = parseFloat(formik?.values?.firstLine) || 0;
    const secondLine = parseFloat(formik?.values?.secondLine) || 0;
    const thirdLine = parseFloat(formik?.values?.thirdLine) || 0;
    const fourthLine = parseFloat(formik?.values?.fourthLine) || 0;
    const fifthLine = parseFloat(formik?.values?.fifthLine) || 0;
    const sixthLine = parseFloat(formik?.values?.sixthLine) || 0;
    const seventhLine = parseFloat(formik?.values?.seventhLine) || 0;
    const eighthLine = parseFloat(formik?.values?.eighthLine) || 0;
    const ninethLine = parseFloat(formik?.values?.ninethLine) || 0;
    const tenthLine = parseFloat(formik?.values?.tenthLine) || 0;
    const eleventhLine = parseFloat(formik?.values?.eleventhLine) || 0;
    const twelvethLine = parseFloat(formik?.values?.twelvethLine) || 0;
    const centreLine = parseFloat(formik?.values?.centreLine) || 0;

    // Calculate totalHoles
    const totalHoles =
      (firstLine +
        secondLine +
        thirdLine +
        fourthLine +
        fifthLine +
        sixthLine +
        seventhLine +
        eighthLine +
        ninethLine +
        tenthLine +
        eleventhLine +
        twelvethLine) *
        2 +
      centreLine;

    // Set the calculated value in Formik field 'totalHoles'
    formik.setFieldValue("totalHoles", totalHoles > 0 ? totalHoles : "");
  };

  const gasketmaterial = [
    { value: "NEOPRENE", label: "NEOPRENE" },
    {
      value: "CS SPIRAL WOUND GRAPHITE FILLED",
      label: "CS SPIRAL WOUND GRAPHITE FILLED",
    },
    {
      value: "SS304 SPIRAL WOUND GRAPHITE FILLED",
      label: "SS304 SPIRAL WOUND GRAPHITE FILLED",
    },
    {
      value: "SS316 SPIRAL WOUND GRAPHITE FILLED",
      label: "SS316 SPIRAL WOUND GRAPHITE FILLED",
    },
    {
      value: "SS321 SPIRAL WOUND GRAPHITE FILLED",
      label: "SS321 SPIRAL WOUND GRAPHITE FILLED",
    },
    {
      value: "INCOLOY800 SPIRAL WOUND GRAPHITE FILLED",
      label: "INCOLOY800 SPIRAL WOUND GRAPHITE FILLED",
    },
    {
      value: "INCONEL600 SPIRAL WOUND GRAPHITE FILLED",
      label: "INCONEL600 SPIRAL WOUND GRAPHITE FILLED",
    },
  ];

  useEffect(() => {
    calculateBaffleWeight();
    const lastHoleCtoC = parseFloat(formik.values.lastHoleCtoC);
    const pitch = parseFloat(formik.values.pitch);
    if (!isNaN(lastHoleCtoC) && !isNaN(pitch)) {
      centreline(lastHoleCtoC, pitch);
      firstline(lastHoleCtoC, pitch);
      secondline(lastHoleCtoC, pitch);
      thirdline(lastHoleCtoC, pitch);
      fourthline(lastHoleCtoC, pitch);
      fifthline(lastHoleCtoC, pitch);
      sixthline(lastHoleCtoC, pitch);
      seventhline(lastHoleCtoC, pitch);
      eigthline(lastHoleCtoC, pitch);
      ninethline(lastHoleCtoC, pitch);
      tenthline(lastHoleCtoC, pitch);
      eleventhline(lastHoleCtoC, pitch);
      twelvethline(lastHoleCtoC, pitch);
    }
  }, [
    formik.values.lastHoleCtoC,
    formik.values.pitch,
    formik.values.baffleHolesOD,
  ]);

  useEffect(() => {
    baffleHolesOD();
  }, [formik.values.baffleOD]);

  useEffect(() => {
    totalHoles();
  }, [
    formik.values.firstLine,
    formik.values.secondLine,
    formik.values.thirdLine,
    formik.values.fourthLine,
    formik.values.fifthLine,
    formik.values.sixthLine,
    formik.values.seventhLine,
    formik.values.eighthLine,
    formik.values.ninethLine,
    formik.values.tenthLine,
    formik.values.eleventhLine,
    formik.values.twelvethLine,
    formik.values.centreLine,
  ]);

  useEffect(() => {
    calculateBaffleOD();
  }, [formik?.values?.innerDiameter, formik?.values?.baffleOD]);

  useEffect(() => {
    if (formik?.values?.FlangeMaterial && formik?.values?.FlangeMaterialCode) {
      fetchMaterialGrade();
    }
  }, [formik?.values?.FlangeMaterial, formik?.values?.FlangeMaterialCode]);

  // useEffect(() => {
  //   const { totalnumberofelements, totalHoles } = formik.values;

  //   if (totalnumberofelements && totalHoles) {
  //     const totalElements = parseInt(totalnumberofelements);
  //     const holes = parseInt(totalHoles);

  //     if (holes > totalElements * 2) {
  //       if (!toastShown) {
  //         showSuccessToast("No of Elements are Correct");
  //         setToastShown(true);
  //       }
  //     } else {
  //       if (!toastShown) {
  //         showErrorToast(
  //           "Please Change Flange size or Decrease the no. of elements"
  //         );
  //         setToastShown(true);
  //       }
  //     }
  //   } else {
  //     setToastShown(false); // Reset toastShown if values are not set
  //   }
  // }, [
  //   formik.values.totalnumberofelements,
  //   formik.values.totalHoles,
  //   toastShown,
  // ]);

  const flangesizechange = (e) => {
    e.preventDefault();
    if (formik?.values?.flangeSize > 14) {
      const innerDiameter = 150 - 2 * formik?.values?.mannualThickness;
      if (!isNaN(innerDiameter)) {
        formik.setFieldValue("innerDiameter", innerDiameter);
      } else {
        formik.setFieldValue("innerDiameter", 0);
        showErrorToast("Please enter a valid value for shell thickness");
      }
    }
  };

  useEffect(() => {
    if (formik?.values?.flangeSize <= 14) {
      formik.setFieldValue("mannualThickness", ""); // Clear mannualThickness if flangeSize is <= 14
    }
  }, [formik?.values?.flangeSize]);

  return (
    <div>
      <div className="w-100 row px-4 col-md-12 col-sm-12">
        <div className="d-flex w-100 mb-15 row">
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="designTemperaturemin">
              Design Temperature( Min / Max )
            </label>
            <div className="d-flex">
              <div className="">
                <input
                  type="text"
                  className="form-control"
                  name="designTemperaturemin"
                  id="designTemperaturemin"
                  onChange={(e) => {
                    formik?.handleChange(e);
                    e.preventDefault();
                  }}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    e.preventDefault();
                  }}
                  value={formik?.values?.designTemperaturemin || ""}
                  placeholder="Design Temperature"
                />
              </div>
              <div className="ml-3">
                <input
                  type="text"
                  className="form-control"
                  name="designTemperature"
                  id="designTemperature"
                  onChange={(e) => {
                    formik?.handleChange(e);
                    e.preventDefault();
                  }}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    e.preventDefault();
                  }}
                  value={formik?.values?.designTemperature || ""}
                  placeholder="Design Temperature"
                />
                {formik.touched.designTemperature &&
                formik.errors?.designTemperature ? (
                  <div className="error">
                    {formik.errors?.designTemperature}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="designPressure">
              Design Pressure (INT/EXT) (Bar)
            </label>
            <div className="d-flex">
              <div>
                <input
                  name="designPressure"
                  id="designPressure"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.designPressure || ""}
                  placeholder="Design Pressure"
                />
                {formik.touched.designPressure &&
                formik.errors?.designPressure ? (
                  <div className="error">{formik.errors?.designPressure}</div>
                ) : null}
              </div>
              <div className="ml-3">
                <input
                  name="designPressureExt"
                  id="designPressureExt"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={formik?.values?.designPressureExt || ""}
                  onBlur={formik.handleBlur}
                  placeholder="Design Pressure"
                />
                {formik.touched.designPressureExt &&
                formik.errors?.designPressureExt ? (
                  <div className="error">
                    {formik.errors?.designPressureExt}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <label htmlFor="elementDiameter">Element Diameter (mm)</label>
            <Select
              id="heatersheathtubedia"
              name="heatersheathtubedia"
              onBlur={formik.handleBlur}
              value={
                formik.values.heatersheathtubedia
                  ? {
                      value: formik.values.heatersheathtubedia,
                      label: formik.values.heatersheathtubedia,
                    }
                  : null
              }
              isDisabled
            />
            {formik.touched.elementDiameter &&
            formik.errors?.elementDiameter ? (
              <div className="error">{formik.errors?.elementDiameter}</div>
            ) : null}
          </div>

          <div className="col-lg-3 col-md-6">
            <label htmlFor="flangeSelection">Type of Flange Selection</label>
            <Select
              name="flangeSelection"
              id="flangeSelection"
              options={[
                { value: "BLRF", label: "BLRF" },
                { value: "BLFF", label: "BLFF" },
                { value: "BLRTJ", label: "BLRTJ" },
              ]}
              onChange={(option) =>
                formik.setFieldValue("flangeSelection", option.value)
              }
              value={
                formik?.values?.flangeSelection
                  ? {
                      value: formik?.values?.flangeSelection,
                      label: formik?.values?.flangeSelection,
                    }
                  : null
              }
              placeholder="Select Flange Type"
            />
          </div>
        </div>

        <div className="d-flex w-100 mb-15 row">
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="FlangeMaterial">Material:</label>
            <Select
              name="FlangeMaterial"
              id="FlangeMaterial"
              options={materials}
              onChange={(option) => {
                if (option?.value) {
                  formik.setFieldValue("FlangeMaterial", option?.value);
                  handleMaterialSelect(option);
                }
              }}
              defaultValue={formik.values.FlangeMaterial}
              placeholder="Select Material"
              value={
                formik?.values?.FlangeMaterial
                  ? {
                      value: formik?.values?.FlangeMaterial,
                      label: formik?.values?.FlangeMaterial,
                    }
                  : null
              }
            />
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="FlangeMaterialCode">Material Code:</label>
            <Select
              name="FlangeMaterialCode"
              id="FlangeMaterialCode"
              options={materialCode}
              onChange={(option) =>
                formik.setFieldValue("FlangeMaterialCode", option?.value)
              }
              defaultValue={formik.values.FlangeMaterialCode}
              placeholder="Select Material Code"
              value={
                formik?.values?.FlangeMaterialCode
                  ? {
                      value: formik?.values?.FlangeMaterialCode,
                      label: formik?.values?.FlangeMaterialCode,
                    }
                  : null
              }
            />
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="MaterialGrade"> Material Grade :</label>
            <input
              name="MaterialGrade"
              id="MaterialGrade"
              placeholder="Select Material Grade"
              value={formik?.values?.MaterialGrade}
              className="form-control"
              readOnly
            />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="maxPressure">Max Pressure (Bar)</label>
            <input
              name="maxPressure"
              id="maxPressure"
              type="text"
              className="form-control"
              onBlur={formik?.handleBlur}
              onChange={formik?.handleChange}
              value={formik?.values?.maxPressure}
              placeholder="Max Pressure"
            />
            {formik?.values?.designPressure > formik?.values?.maxPressure ? (
              <div className="error">
                Max pressure should be greater than Design Pressure
              </div>
            ) : null}
            {formik.touched.maxPressure && formik.errors?.maxPressure ? (
              <div className="error">{formik.errors?.maxPressure}</div>
            ) : null}
          </div>
        </div>

        <div className="d-flex w-100 mb-15 row">
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="">Flange Size: </label>
            <Select
              name="flangeSize"
              id="flangeSize"
              options={flangeSizeOptions}
              placeholder="Select Flange Size"
              value={
                formik?.values?.flangeSize
                  ? flangeSizeOptions.find(
                      (option) => option.value === formik.values.flangeSize
                    )
                  : null
              }
              onChange={(option) => {
                formik.setFieldValue("flangeSize", option.value); // only set the value in Formik
              }}
              onBlur={(e) => {
                e.preventDefault();
                handleFlangeThickness();
              }}
            />
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="FlangeClass">Flange Class :</label>
            <Select
              name="FlangeClass"
              id="FlangeClass"
              options={[
                { value: "150", label: "150" },
                { value: "300", label: "300" },
                { value: "400", label: "400" },
                { value: "600", label: "600" },
                { value: "900", label: "900" },
                { value: "1200", label: "1200" },
                { value: "1500", label: "1500" },
                { value: "2500", label: "2500" },
              ]}
              placeholder="Select Flange Class"
              value={
                formik?.values?.FlangeClass
                  ? {
                      value: formik?.values?.FlangeClass,
                      label: formik?.values?.FlangeClass,
                    }
                  : null
              }
              onChange={(option) => {
                formik.setFieldValue("FlangeClass", option.value);
                formik.setFieldValue("nozzle[0].flangeRating", option.value);
                formik.setFieldValue("nozzle[1].flangeRating", option.value);
                formik.setFieldValue("nozzle[2].flangeRating", option.value);
                formik.setFieldValue("nozzle[3].flangeRating", option.value);
                formik.setFieldValue("nozzle[4].flangeRating", option.value);
              }}
              onBlur={(e) => {
                e.preventDefault();
                handleFlangeThickness();
              }}
            />
          </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="schedule">Schedule</label>
              <Select
                name="schedule"
                id="schedule"
                options={schedules?.map((schedule) => ({
                  value: schedule.sch,
                  label: schedule.sch,
                }))}
                placeholder="Schedule"
                value={
                  formik?.values?.schedule
                    ? {
                        value: formik?.values?.schedule,
                        label: formik?.values?.schedule,
                      }
                    : null
                }
                onChange={(option) => {
                  formik.setFieldValue("schedule", option.value);
                  formik.setFieldValue("nozzle[0].schedules", option.value);
                  formik.setFieldValue("nozzle[1].schedules", option.value);
                  formik.setFieldValue("nozzle[2].schedules", option.value);
                  formik.setFieldValue("nozzle[3].schedules", option.value);
                  formik.setFieldValue("nozzle[4].schedules", option.value);
                }}
              />
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              {formik?.values?.flangeSize > 14 ? (
                <>
                  <label htmlFor="shellThickness">Enter Thickness</label>
                  <input
                    type="text"
                    name="mannualThickness"
                    id="mannualThickness"
                    className="form-control"
                    onChange={formik?.handleChange}
                    value={formik.values.mannualThickness}
                    placeholder="Enter Thickness"
                    onBlur={formik.handleBlur}
                  />
                </>
              ) : (
                <>
                  <label htmlFor="calculatedShellSize">
                    Cal. Shell Thickness
                  </label>
                  <input
                    type="text"
                    name="shellThickness"
                    id="shellThickness"
                    className="form-control"
                    value={formik?.values?.shellThickness}
                    readOnly
                  />
                </>
              )}
            </div>


          <div className="col-lg-6 col-md-12 col-sm-12">
            <label htmlFor="" style={{ color: "white" }}>
              calculate
            </label>
            <button
              className="btn btn-primary bg-primary form-control"
              onClick={(e) => {
                console.log("button events", e);
                formik?.values?.flangeSize > 14
                  ? flangesizechange(e)
                  : getData(e);
              }}
            >
              <h6>Calculate</h6>
            </button>
          </div>
        </div>

        <div className="d-flex w-100 mb-15 row">
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="innerDiameter">Inner Diameter (mm)</label>
            <input
              type="text"
              className="form-control"
              name="innerDiameter"
              id="innerDiameter"
              onChange={formik?.handleChange}
              value={formik?.values?.innerDiameter}
              placeholder="Inner Diameter"
            />
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="terminalBoxSize">
              Recommended Terminal Box Size
            </label>
            {formik.values.areaClassification === "safe" ? (
              <input
                type="text"
                className="form-control"
                id="terminalBoxSize"
                name="terminalBoxSize"
                onChange={formik.handleChange}
                value={formik.values.terminalBoxSize}
                placeholder="Terminal Box Size"
              />
            ) : formik.values.areaClassification === "hazardous" ? (
              <Select
                id="terminalBoxSize"
                name="terminalBoxSize"
                placeholder="Terminal Box Size"
                options={[
                  { value: "MHT-Ex d-100", label: "MHT-Ex d-100" },
                  { value: "MHT-Ex d-125", label: "MHT-Ex d-125" },
                  { value: "MHT-Ex d-150", label: "MHT-Ex d-150" },
                  { value: "MHT-Ex d-200", label: "MHT-Ex d-200" },
                  { value: "MHT-Ex d-250", label: "MHT-Ex d-250" },
                  { value: "MHT-Ex d-300", label: "MHT-Ex d-300" },
                  { value: "MHT-Ex d-350", label: "MHT-Ex d-350" },
                  { value: "MHT-Ex d-400", label: "MHT-Ex d-400" },
                  { value: "MHT-Ex d-450", label: "MHT-Ex d-450" },
                  { value: "MHT-Ex d-500", label: "MHT-Ex d-500" },
                  { value: "MHT-Ex d-550", label: "MHT-Ex d-550" },
                  { value: "MHT-Ex d-600", label: "MHT-Ex d-600" },
                  { value: "MHT-Ex d-650", label: "MHT-Ex d-650" },
                ]}
                onBlur={formik.handleBlur}
                onChange={(selectedOption) =>
                  formik.setFieldValue("terminalBoxSize", selectedOption.value)
                }
                value={
                  formik.values.terminalBoxSize
                    ? {
                        value: formik.values.terminalBoxSize,
                        label: formik.values.terminalBoxSize,
                      }
                    : null
                }
              />
            ) : (
              <input
                type="text"
                className="form-control"
                id="terminalBoxSize"
                name="terminalBoxSize"
                onChange={formik.handleChange}
                value={formik.values.terminalBoxSize}
                placeholder="Terminal Box Size"
              />
            )}
          </div>

          {/* <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="outerTubeLimitDiameter">
              Outer Tube Limit Diameter (mm)
            </label>
            <input
              name="outerTubeLimitDiameter"
              id="outerTubeLimitDiameter"
              type="text"
              className="form-control"
              onChange={formik?.handleChange}
              value={formik?.values.outerTubeLimitDiameter}
              placeholder="Outer Tube Limit Diameter"
            />
          </div> */}
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="gaskets">Gaskets</label>
            <Select
              type="text"
              name="gaskets"
              id="gaskets"
              placeholder="Select Gasket"
              options={gasketmaterial}
              onBlur={formik.handleBlur}
              value={
                formik?.values?.gaskets
                  ? {
                      value: formik?.values?.gaskets,
                      label: formik?.values?.gaskets,
                    }
                  : null
              }
              onChange={(option) =>
                formik.setFieldValue("gaskets", option.value)
              }
            />
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="Pitch">Pitch (mm)</label>
            <input
              name="pitch"
              id="pitch"
              type="number"
              className="form-control"
              placeholder="Pitch"
              value={formik?.values?.pitch}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
            />
            {formik.values.pitch === "" ? null : (formik?.values
                ?.heatersheathtubedia === "8" ||
                formik?.values?.heatersheathtubedia === "10") &&
              formik?.values?.pitch < 20 ? (
              <div className="error">Pitch should be greater than 20</div>
            ) : (formik?.values?.heatersheathtubedia === "11" ||
                formik?.values?.heatersheathtubedia === "12" ||
                formik?.values?.heatersheathtubedia === "12.5" ||
                formik?.values?.heatersheathtubedia === "12.7") &&
              formik?.values?.pitch < 24 ? (
              <div className="error">Pitch should be greater than 24</div>
            ) : formik?.values?.heatersheathtubedia === "13.5" &&
              formik?.values?.pitch < 26 ? (
              <div className="error">Pitch should be greater than 26</div>
            ) : formik?.values?.heatersheathtubedia === "16" &&
              formik?.values?.pitch < 32 ? (
              <div className="error">Pitch should be greater than 32</div>
            ) : null}
          </div>
        </div>

        <div className="w-100 d-flex flex-column mb-15 mt-30">
          <label style={{ fontWeight: "bold", marginLeft: "5px" }} htmlFor="">
            Number of Elements
          </label>
          <div className="d-flex w-100">
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15 mt-15">
              <label htmlFor="baffleOD">Baffle OD</label>
              <input
                name="baffleOD"
                id="baffleOD"
                type="text"
                className="form-control"
                onChange={formik?.handleChange}
                value={formik?.values?.baffleOD || ""}
                placeholder="Baffle OD"
              />
            </div>
            {/* <div className="col-lg-3 col-md-6 col-sm-12 mb-15 mt-15">
              <label htmlFor="baffleHolesOD">Baffle Hole OD</label>
              <input
                name="baffleHolesOD"
                id="totalHbaffleHolesODoles"
                type="text"
                className="form-control"
                onChange={formik?.handleChange}
                value={formik?.values?.baffleHolesOD}
                placeholder="Baffle Hole OD"
              />
            </div> */}
            {/* <div className="col-lg-3 col-md-6 col-sm-12 mb-15 mt-15">
              <label htmlFor="lastHoleCtoC">Last Hole C to C</label>
              <input
                name="lastHoleCtoC"
                id="lastHoleCtoC"
                type="text"
                className="form-control"
                onChange={formik?.handleChange}
                value={formik?.values?.lastHoleCtoC}
                placeholder="Last Hole C to C"
              />
            </div> */}
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15 mt-15">
              <label htmlFor="totalHoles">Total Holes</label>
              <input
                name="totalHoles"
                id="totalHoles"
                type="text"
                className="form-control"
                onChange={formik?.handleChange}
                value={formik?.values?.totalHoles}
                placeholder="Total Holes"
              />
              {formik?.values?.totalHoles === "" ? null : formik?.values
                  ?.totalHoles >
                formik?.values?.totalnumberofelements * 2 ? (
                <div className="success">Number of elements are correct</div>
              ) : (
                <div className="error">
                  Please Change Flange size or Decrease the no. of elements
                </div>
              )}
            </div>

            {/* <div className="col-lg-3 col-md-6 col-sm-12 mb-15 mt-15">
                <label htmlFor="fortyfives">45-S</label>
                <input
                  name="fortyfives"
                  id="fortyfives"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={formik?.values.fortyfives}
                  placeholder="45-S"
                />
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 mb-15 mt-15">
                <label htmlFor="sixtyt">60-T</label>
                <input
                  name="sixtyt"
                  id="sixtyt"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={formik?.values.sixtyt}
                  placeholder="60-T"
                />
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 mb-15 mt-15">
                <label htmlFor="ninetys">90-S</label>
                <input
                  name="ninetys"
                  id="ninetys"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={formik?.values.ninetys}
                  placeholder="90-S"
                />
              </div> */}
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
}

export default Screen4;
