import Select from "react-select";
import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { useLoader } from "../../context/Loader/LoaderProvider";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";

import axiosPrivate from "../../hooks/axiosPrivate";
function Screen3({ formik, isPageComplete, handleFlangeThickness }) {
  const [sheathMaterialDropdown, setSheathMaterialDropdown] = useState([]);
  const { isLoading, setIsLoading } = useLoader();

  const [totalCount, setTotalCount] = useState(9);
  const [actualCount, setActualCount] = useState(0);
  const handleInputValue = (value, count) => {
    if (value === "") {
      return count > 0 ? count - 1 : count;
    }
    if (value === undefined) {
      return count + 0;
    }
    if (value !== undefined) {
      return count + 1;
    }
  };
  useEffect(() => {
    let count = 0;
    let total_count = totalCount;
    count = handleInputValue(formik?.values?.heatersheathtubedia, count);
    // count = handleInputValue(
    //   formik?.values?.distbwheaterflangetoterminalbox,
    //   count
    // );
    count = handleInputValue(formik?.values?.maxactivehotlength, count);
    count = handleInputValue(formik?.values?.maxactivecoldlength, count);
    count = handleInputValue(formik?.values?.sheathmaterial, count);
    count = handleInputValue(formik?.values?.fixingofheatingelements, count);
    count = handleInputValue(formik?.values?.distancebwionozzels, count);
    count = handleInputValue(formik?.values?.totalnumberofelements, count);
    count = handleInputValue(formik?.values?.numberofelementsconnected, count);
    count = handleInputValue(formik?.values?.bundleimmersionlength, count);

    setActualCount(count);
  }, [formik.values]);

  useEffect(() => {
    isPageComplete(3, totalCount, actualCount);
  }, [actualCount, totalCount]);
  const [selectedNutAssembly, setSeselectedNutAssembly] = useState("");
  const [fixingOfHeatingElements, setFixingOfHeatingElements] = useState(
    formik.values.fixingofheatingelements || ""
  );
  const handleFixingChange = (option) => {
    setFixingOfHeatingElements(option.value);
    formik.setFieldValue("fixingofheatingelements", option.value);
  };
  const [baffleType, setBaffleType] = useState(formik.values.baffletype || "");
  const handleBaffleTypeChange = (option) => {
    setBaffleType(option.value);
    formik.setFieldValue("baffletype", option.value);
  };
  const handleNutAssembly = (e) => {
    const nutAssembly = e.target.value;
    formik.setFieldValue("nutAssembly", nutAssembly);
    setSeselectedNutAssembly(nutAssembly);
  };

  useEffect(() => {
    handleimmersionlength();
    handleDevelopedLength();
    // formik.setFieldValue(
    //   "distancebwionozzels",
    //   formik.values.maxactivehotlength
    // );
  }, [
    formik?.values?.maxactivehotlength,
    formik?.values?.maxactivecoldlength,
    formik?.values?.wattdensity,
    formik?.values?.distbwheaterflangetoterminalbox,
  ]);

  const validateInputs = () => {
    const isNutSelected = formik.values.fixingofheatingelements === "Nut";

    const requiredFields = isNutSelected
      ? [formik.values.nutsize]
      : [
          formik.values.installedCapacity,
          formik.values.wattdensity,
          formik.values.heatersheathtubedia,
          formik.values.maxactivehotlength,
        ];

    // Check if fields are missing or invalid
    const missingFields = requiredFields
      .map((field, index) => ({
        field: field,
        name: isNutSelected
          ? "Nut Size"
          : [
              "Power",
              "Watt Density",
              "Element Diameter",
              "Max Hot Active Length",
            ][index],
      }))
      .filter(({ field }) => field === "" || isNaN(parseFloat(field)))
      .map(({ name }) => name);

    if (missingFields.length > 0) {
      showErrorToast(
        `Please fill all the required details: ${missingFields.join(", ")}`
      );
      return false;
    }

    return true;
  };

  const handleTotalElements = (noOfElementsConnected, spareElements) => {
    const totalNoOfElements =
      spareElements > 0
        ? noOfElementsConnected + spareElements
        : noOfElementsConnected;

    formik.setFieldValue("totalnumberofelements", totalNoOfElements);
    return totalNoOfElements;
  };

  const handleConnectedElements = () => {
    const power = parseFloat(formik.values.installedCapacity) * 1000;
    const wattDensity = parseFloat(formik.values.wattdensity);
    const flangeDiameter = parseFloat(formik.values.heatersheathtubedia);
    const hotActiveLength = parseFloat(formik.values.maxactivehotlength);
    const nutSize = parseFloat(formik.values.nutsize);

    if (formik.values.fixingofheatingelements === "Nut") {
      if (isNaN(nutSize)) {
        formik.setFieldValue("numberofelementsconnected", "");
        return NaN;
      }
      const elementsConnected = nutSize * 3;
      formik.setFieldValue("numberofelementsconnected", elementsConnected);
      return elementsConnected;
    }

    if (
      isNaN(power) ||
      isNaN(wattDensity) ||
      isNaN(flangeDiameter) ||
      isNaN(hotActiveLength)
    ) {
      formik.setFieldValue("numberofelementsconnected", "");
      return NaN;
    }

    const elementsConnected =
      ((power / wattDensity) * 3.98 * 8) /
      (flangeDiameter * hotActiveLength * 2);
    const roundedTotal = Math.ceil(elementsConnected / 3) * 3;
    formik.setFieldValue("numberofelementsconnected", roundedTotal);
    return roundedTotal;
  };

  const handleSpareElements = (noOfElementsConnected) => {
    const inbuildSpare =
      formik.values.inbuildspare !== ""
        ? parseFloat(formik.values.inbuildspare)
        : 0;

    if (isNaN(inbuildSpare) || isNaN(noOfElementsConnected)) {
      formik.setFieldValue("numberofelementsspare", "");
      return NaN;
    }

    let spareElements = 0;
    if (inbuildSpare > 0) {
      if (formik.values.fixingofheatingelements === "Nut") {
        spareElements =
          Math.ceil((noOfElementsConnected * inbuildSpare) / 100 / 3) * 3;
      } else {
        spareElements = Math.ceil((noOfElementsConnected * inbuildSpare) / 100);
      }
    }

    formik.setFieldValue("numberofelementsspare", spareElements);
    return spareElements;
  };

  const calculateElements = (e) => {
    e?.preventDefault();

    if (!validateInputs()) return; // Exit if validation fails

    const noOfElementsConnected = handleConnectedElements();
    if (!isNaN(noOfElementsConnected)) {
      const spareElements = handleSpareElements(noOfElementsConnected);
      if (!isNaN(spareElements)) {
        const totalNoOfElements = handleTotalElements(
          noOfElementsConnected,
          spareElements
        );

        const bundleHeatLoad = parseFloat(formik.values.bundleheatload);
        // const connectedelements = parseInt(formik?.values?.tot)
        if (
          !isNaN(bundleHeatLoad) &&
          !isNaN(totalNoOfElements) &&
          totalNoOfElements > 0
        ) {
          const IndElementMaxWattage = (
            bundleHeatLoad / noOfElementsConnected
          ).toFixed(3);
          formik.setFieldValue(
            "individualelementmaxwattage",
            IndElementMaxWattage
          );
        } else {
          formik.setFieldValue("individualelementmaxwattage", "");
        }
      }
    }
  };

  useEffect(() => {
    getSheathMaterialDropdown();
  }, []);
  // Ensure that manual input of numberofelementsconnected is handled correctly
  const handleManualInput = (field, value) => {
    formik.setFieldValue(field, value);
    if (field === "numberofelementsspare") {
      calculateElements();
    }
  };
  const handleimmersionlength = () => {
    const maxactivehotlength = parseFloat(formik.values.maxactivehotlength);
    const maxactivecoldlength = parseFloat(formik.values.maxactivecoldlength);

    if (isNaN(maxactivehotlength) || isNaN(maxactivecoldlength)) {
      formik.setFieldValue("bundleimmersionlength", "");
      return;
    }

    const immersionlength = maxactivehotlength + maxactivecoldlength;

    if (maxactivecoldlength === "" || maxactivehotlength === "") {
      formik.setFieldValue("bundleimmersionlength", null);
    } else {
      formik.setFieldValue("bundleimmersionlength", immersionlength);
    }
  };

  const handleDevelopedLength = () => {
    const maxActiveHotLength = parseFloat(formik.values.maxactivehotlength);
    const maxActiveColdLength = parseFloat(formik.values.maxactivecoldlength);
    const distbwheaterflangetoterminalbox = parseFloat(
      formik.values.distbwheaterflangetoterminalbox
    );

    if (isNaN(maxActiveHotLength) || isNaN(maxActiveColdLength)) {
      formik.setFieldValue("developedlength", "");
      return;
    }

    let developedLength = maxActiveHotLength + maxActiveColdLength;

    if (!isNaN(distbwheaterflangetoterminalbox)) {
      developedLength += distbwheaterflangetoterminalbox;
    }

    developedLength *= 2;

    formik.setFieldValue("developedlength", developedLength);
  };

  const getSheathMaterialDropdown = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("/sheath-material ");
      if (response.status === 200) {
        setSheathMaterialDropdown(
          response.data?.data.map((item) => {
            return { value: item.name, label: item.name };
          })
        );
        console.log("'''''''''''''''''''''''''", sheathMaterialDropdown);
      }
    } catch (error) {
      if (error.response?.status === 403) {
        showErrorToast(error.message);
      } else if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const materialOptions = [
    { value: "CS", label: "CS" },
    { value: "SS304", label: "SS304" },
    { value: "SS316", label: "SS316" },
    { value: "SS316L", label: "SS316L" },
    { value: "SS321", label: "SS321" },
    { value: "SS321H", label: "SS321H" },
    { value: "INCOLOY800", label: "INCOLOY800" },
    { value: "GR11", label: "GR11" },
    { value: "MS", label: "MS" },
  ];
  useEffect(() => {
    const wattdensityactual = () => {
      const {
        installedCapacity,
        numberofelementsconnected,
        heatersheathtubedia,
        maxactivehotlength,
      } = formik.values;
      const power = parseFloat(installedCapacity) * 1000;
      const wattdensityactual =
        (power * 100) /
        (numberofelementsconnected *
          3.14 *
          heatersheathtubedia *
          maxactivehotlength *
          2);
      formik.setFieldValue("wattdensityactual", wattdensityactual);
    };
    wattdensityactual();
  }, [formik.values.numberofelementsconnected]);
  // const flangeSizeMapping = {
  //   "8": 10,
  //   "10": 12,
  //   "11": 12,
  //   "12": 14,
  //   "12.5": 14,
  //   "12.7": 14,
  //   "13.5": 14,
  //   "16": 16,
  // };

  const handleSpareChanges = (e) => {
    const spareElements = parseInt(e.target.value) || 0;
    const connectedElements = formik.values.numberofelementsconnected || 0;
    const totalElements = spareElements + connectedElements;
  
    // Update the total number of elements in Formik
    formik.setFieldValue("totalnumberofelements", totalElements);
  };
  

  return (
    <div>
      <div className="w-100 row px-4 col-md-12 col-sm-12">
        <div className="mb-10">
          <label>
            <b>Element Selection</b>
          </label>
        </div>
        <div className="d-flex w-100 row">
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="heatersheathtubedia">Heater Sheath Tube DIA</label>
            <span style={{ color: "red" }}>*</span>
            <Select
              id="heatersheathtubedia"
              name="heatersheathtubedia"
              type="number"
              options={[
                { value: "8", label: "8" },
                { value: "10", label: "10" },
                { value: "11", label: "11" },
                { value: "12", label: "12" },
                { value: "12.5", label: "12.5" },
                { value: "12.7", label: "12.7" },
                { value: "13.5", label: "13.5" },
                { value: "16", label: "16" },
              ]}
              onBlur={formik.handleBlur}
              onChange={(option) => {
                formik.setFieldValue("heatersheathtubedia", option.value);
                // const flangeSize = flangeSizeMapping[option.value];
                // if (flangeSize !== undefined) {
                //   formik.setFieldValue("flangeSize", flangeSize);
                // }
              }}
              value={
                formik.values.heatersheathtubedia
                  ? {
                      value: formik.values.heatersheathtubedia,
                      label: formik.values.heatersheathtubedia,
                    }
                  : null
              }
            />
            {formik.touched.heatersheathtubedia &&
            formik.errors.heatersheathtubedia ? (
              <div className="error">{formik.errors.heatersheathtubedia}</div>
            ) : null}
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="sheathmaterial"> Sheath Material </label>
            <Select
              id="sheathmaterial"
              name="sheathmaterial"
              options={sheathMaterialDropdown}
              onChange={(selectedOption) => {
                formik.setFieldValue("sheathmaterial", selectedOption.value);
                formik.setFieldValue("tiematerial", selectedOption.value);
                formik.setFieldValue("bafflematerial", selectedOption.value);
              }}
              value={
                formik.values.sheathmaterial
                  ? {
                      value: formik.values.sheathmaterial,
                      label: formik.values.sheathmaterial,
                    }
                  : null
              }
              onBlur={formik.handleBlur}
            />
            {formik.touched.sheathmaterial && formik.errors.sheathmaterial ? (
              <div className="error">{formik.errors.sheathmaterial}</div>
            ) : null}
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="sheathtubethickness">
              Sheathing Tube Thickness (mm)
            </label>
            <Select
              id="sheathtubethickness"
              name="sheathtubethickness"
              options={[
                { value: "0.5", label: "0.5" },
                { value: "0.6", label: "0.6" },
                { value: "0.7", label: "0.7" },
                { value: "0.8", label: "0.8" },
                { value: "0.9", label: "0.9" },
                { value: "1", label: "1" },
                { value: "1.1", label: "1.1" },
                { value: "1.2", label: "1.2" },
                { value: "1.25", label: "1.25" },
                { value: "1.5", label: "1.5" },
                { value: "1.6", label: "1.6" },
                { value: "2", label: "2" },
              ]}
              onChange={(option) =>
                formik.setFieldValue("sheathtubethickness", option.value)
              }
              value={
                formik.values.sheathtubethickness
                  ? {
                      value: formik.values.sheathtubethickness,
                      label: formik.values.sheathtubethickness,
                    }
                  : null
              }
              onBlur={formik.handleBlur}
            />
            {formik.touched.sheathtubethickness &&
            formik.errors.sheathtubethickness ? (
              <div className="error">{formik.errors.sheathtubethickness}</div>
            ) : null}
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="sheathtubetype"> Sheathing Tube Type </label>
            <Select
              id="sheathtubetype"
              name="sheathtubetype"
              options={[
                { value: "Welded(ERW)", label: "Welded(ERW)" },
                { value: "Seamless", label: "Seamless" },
                { value: "Welded Seamless", label: "Welded Seamless" },
              ]}
              onChange={(option) =>
                formik.setFieldValue("sheathtubetype", option.value)
              }
              value={
                formik.values.sheathtubetype
                  ? {
                      value: formik.values.sheathtubetype,
                      label: formik.values.sheathtubetype,
                    }
                  : null
              }
              onBlur={formik.handleBlur}
            />
            {formik.touched.sheathtubetype && formik.errors.sheathtubetype ? (
              <div className="error">{formik.errors.sheathtubetype}</div>
            ) : null}
          </div>
        </div>
        <div className="d-flex w-100 row">
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="distbwheaterflangetoterminalbox">
              Dist.B/W Heater Flange to Ter. Box
            </label>
            <span style={{ color: "red" }}>*</span>
            <CreatableSelect
              name="distbwheaterflangetoterminalbox"
              id="distbwheaterflangetoterminalbox"
              placeholder="Distance"
              options={[
                { value: "0", label: "0" },
                { value: "50", label: "50" },
                { value: "100", label: "100" },
                { value: "150", label: "150" },
                { value: "200", label: "200" },
                { value: "250", label: "250" },
                { value: "300", label: "300" },
                { value: "350", label: "350" },
              ]}
              onChange={(option) =>
                formik.setFieldValue(
                  "distbwheaterflangetoterminalbox",
                  option.value
                )
              }
              value={
                formik.values.distbwheaterflangetoterminalbox
                  ? {
                      value: formik.values.distbwheaterflangetoterminalbox,
                      label: formik.values.distbwheaterflangetoterminalbox,
                    }
                  : null
              }
            />
            {formik.touched.distbwheaterflangetoterminalbox &&
            formik.errors.distbwheaterflangetoterminalbox ? (
              <div className="error">
                {formik.errors.distbwheaterflangetoterminalbox}
              </div>
            ) : null}
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="maxactivehotlength"> Max. Active Length </label>
            <span style={{ color: "red" }}>*</span>
            <input
              type="text"
              className="form-control"
              placeholder="Max. Active Hot Length"
              name="maxactivehotlength"
              value={formik.values.maxactivehotlength}
              onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              onBlur={() => {
                formik.setFieldValue(
                  "distancebwionozzels",
                  formik.values.maxactivehotlength
                );
              }}
              required
            />
            {formik.touched.maxactivehotlength &&
            formik.errors.maxactivehotlength ? (
              <div className="error">{formik.errors.maxactivehotlength}</div>
            ) : null}
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="maxactivecoldlength"> Max. Cold Length </label>
            <span style={{ color: "red" }}>*</span>
            {/* <input
              value={parseInt(formik.values.maxactivecoldlength).toFixed(2)}
              onBlur={formik.handleBlur}
              required
            /> */}
            <input
              type="text"
              className="form-control"
              placeholder="Max. Active Cold Length"
              name="maxactivecoldlength"
              value={parseFloat(formik.values.maxactivecoldlength).toFixed(2)}
              onChange={(e) => {
                e.preventDefault();
                formik.setFieldValue("maxactivecoldlength", e.target.value);
                handleimmersionlength();
              }}
              onBlur={(e) => {
                formik.setFieldValue(
                  "maxactivecoldlength",
                  Number(e.target.value).toFixed(2)
                );
                formik.handleBlur(e);
              }}
              required
            />
            {formik.touched.maxactivecoldlength &&
            formik.errors.maxactivecoldlength ? (
              <div className="error">{formik.errors.maxactivecoldlength}</div>
            ) : null}
          </div>
          <div className="col-lg-3 col-md-6">
            <label htmlFor="wattdensity">
              {" "}
              Watt.Density(Recommended/Actual)
            </label>
            <div className="d-flex">
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Watt Density(Recommended)"
                  name="wattdensity"
                  id="wattdensity"
                  value={formik.values.wattdensity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.wattdensity && formik.errors.wattdensity ? (
                  <div className="error">{formik.errors.wattdensity}</div>
                ) : null}
              </div>
              <div className="ml-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Watt Density(Actual)"
                  name="wattdensityactual"
                  id="wattdensityactual"
                  value={parseFloat(formik?.values?.wattdensityactual).toFixed(
                    5
                  )}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.wattdensityactual &&
                formik.errors.wattdensityactual ? (
                  <div className="error">{formik.errors.wattdensityactual}</div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex w-100 row">
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <button
              onClick={calculateElements}
              className="btn btn-primary bg-primary form-control mt-25"
            >
              <h6>Calculate</h6>
            </button>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="inbuildspare">Heater Spare Capacity (%)</label>
            <input
              type="text"
              className="form-control"
              placeholder="Heater Spare Capacity"
              name="inbuildspare"
              id="inbuildspare"
              maxLength={100}
              value={formik && formik?.values.inbuildspare}
              onChange={formik && formik?.handleChange}
              onBlur={formik && formik?.handleblur}
              readOnly
            />
            {formik.touched.inbuildspare && formik.errors.inbuildspare ? (
              <div className="error">{formik.errors.inbuildspare}</div>
            ) : null}
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="individualelementmaxwattage">
              Ind. Element Max Wattage{""} (KW)
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Ind. Element Max Wattage"
              name="individualelementmaxwattage"
              value={parseFloat(
                formik.values.individualelementmaxwattage
              ).toFixed(2)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              readOnly
            />
            {formik.touched.individualelementmaxwattage &&
            formik.errors.individualelementmaxwattage ? (
              <div className="error">
                {formik.errors.individualelementmaxwattage}
              </div>
            ) : null}
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="developedlength"> Total Straight Length </label>
            <input
              type="text"
              className="form-control"
              placeholder="Total Straight Length"
              name="developedlength"
              value={Math.round(formik.values.developedlength)}
              onChange={formik.handleChange}
              readOnly
              onBlur={formik.handleBlur}
            />
            {formik.touched.developedlength && formik.errors.developedlength ? (
              <div className="error">{formik.errors.developedlength}</div>
            ) : null}
          </div>
        </div>
        <div className="d-flex w-100 row">
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="totalnumberofelements">Total No. of Elements</label>
            <input
              readOnly
              type="text"
              className="form-control"
              placeholder="Total No. of Elements"
              name="totalnumberofelements"
              value={formik.values.totalnumberofelements}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.totalnumberofelements &&
            formik.errors.totalnumberofelements ? (
              <div className="error">{formik.errors.totalnumberofelements}</div>
            ) : null}
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="numberofelementsconnected">
              No. of Elements Connected
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="No. of Elements Connected"
              name="numberofelementsconnected"
              value={formik.values.numberofelementsconnected}
              onChange={(e) =>
                handleManualInput("numberofelementsconnected", e.target.value)
              }
              readOnly
              onBlur={formik.handleBlur}
            />
            {formik.touched.numberofelementsconnected &&
            formik.errors.numberofelementsconnected ? (
              <div className="error">
                {formik.errors.numberofelementsconnected}
              </div>
            ) : null}
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="numberofelementsspare">No. of Elements Spare</label>
            <input
              name="numberofelementsspare"
              type="text"
              className="form-control"
              placeholder="No. of Elements Spare"
              value={formik.values.numberofelementsspare}
              onChange={(e) => {
                e.preventDefault();
                formik.setFieldValue("numberofelementsspare", e.target.value);
                handleSpareChanges(e);
              }}
              onBlur={(e) => {
                formik.setFieldValue(
                  "numberofelementsspare", (e.target.value)
                );
                formik.handleBlur(e);
              }}
            />
            {formik.touched.numberofelementsspare &&
            formik.errors.numberofelementsspare ? (
              <div className="error">{formik.errors.numberofelementsspare}</div>
            ) : null}
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="bundleimmersionlength">
              Bundle Immersion Length
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Bundle Immersion Length"
              name="bundleimmersionlength"
              value={formik.values.bundleimmersionlength}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.bundleimmersionlength &&
            formik.errors.bundleimmersionlength ? (
              <div className="error">{formik.errors.bundleimmersionlength}</div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="w-100 row px-4 col-md-12 col-sm-12">
        <div className="mb-10">
          <label>
            <b>Baffles and Tie Rod</b>
          </label>
        </div>
        <div className="d-flex w-100 row">
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="numberofbaffles">No. of Baffles</label>
            <input
              name="numberofbaffles"
              type="text"
              id="numberofbaffles"
              className="form-control"
              placeholder="No. of Baffles"
              value={formik.values.numberofbaffles}
              onChange={formik.handleChange}
            />
            {formik.touched.numberofbaffles && formik.errors.numberofbaffles ? (
              <div className="error">{formik.errors.numberofbaffles}</div>
            ) : null}
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="baffleplatethickness">Baffle Plate Thickness</label>
            <Select
              id="baffleplatethickness"
              name="baffleplatethickness"
              options={[
                { value: "2", label: "2" },
                { value: "3", label: "3" },
                { value: "4", label: "4" },
                { value: "5", label: "5" },
                { value: "6", label: "6" },
              ]}
              onChange={(option) =>
                formik.setFieldValue("baffleplatethickness", option.value)
              }
              value={
                formik.values.baffleplatethickness
                  ? {
                      value: formik.values.baffleplatethickness,
                      label: formik.values.baffleplatethickness,
                    }
                  : null
              }
              onBlur={formik.handleBlur}
            />
            {formik.touched.baffleplatethickness &&
            formik.errors.baffleplatethickness ? (
              <div className="error">{formik.errors.baffleplatethickness}</div>
            ) : null}
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="baffletype">Baffle Type</label>
            <Select
              id="baffletype"
              name="baffletype"
              options={[
                { value: "Segmental", label: "Segmental" },
                { value: "Rod Baffle", label: "Rod Baffle" },
                { value: "Strip Baffle", label: "Strip Baffle" },
                { value: "Guide Flow Baffle", label: "Guide Flow Baffle" },
                { value: "Wire Mesh", label: "Wire Mesh" },
              ]}
              onBlur={formik.handleBlur}
              onChange={handleBaffleTypeChange}
              value={
                baffleType ? { value: baffleType, label: baffleType } : null
              }
            />
            {formik.touched.baffletype && formik.errors.baffletype ? (
              <div className="error">{formik.errors.baffletype}</div>
            ) : null}
          </div>
          {formik?.values?.baffletype === "Segmental" && (
            <>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="bafflecut">Baffles Cut</label>
                <input
                  name="bafflecut"
                  type="text"
                  id="bafflecut"
                  className="form-control"
                  placeholder="Enter Baffle Cut"
                  value={formik.values.bafflecut}
                  onChange={formik.handleChange}
                />
                {formik.touched.bafflecut && formik.errors.bafflecut ? (
                  <div className="error">{formik.errors.bafflecut}</div>
                ) : null}
              </div>
            </>
          )}

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="bafflematerial">Baffle Material</label>
            <Select
              id="bafflematerial"
              name="bafflematerial"
              options={materialOptions}
              onChange={(option) => {
                formik.setFieldValue("bafflematerial", option.value);
              }}
              value={
                formik.values.bafflematerial
                  ? {
                      value: formik.values.bafflematerial,
                      label: formik.values.bafflematerial,
                    }
                  : null
              }
              onBlur={formik.handleBlur}
            />
            {formik.touched.bafflematerial && formik.errors.bafflematerial ? (
              <div className="error">{formik.errors.bafflematerial}</div>
            ) : null}
          </div>
        </div>
        <div className="d-flex w-100 row">
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="tieroddia"> Tie Rod Dia </label>
            <Select
              id="tieroddia"
              name="tieroddia"
              options={[
                { value: "8", label: "8" },
                { value: "10", label: "10" },
                { value: "12", label: "12" },
                { value: "16", label: "16" },
                { value: "20", label: "20" },
              ]}
              onBlur={formik.handleBlur}
              onChange={(option) =>
                formik.setFieldValue("tieroddia", option.value)
              }
              value={
                formik.values.tieroddia
                  ? {
                      value: formik.values.tieroddia,
                      label: formik.values.tieroddia,
                    }
                  : null
              }
            />
            {formik.touched.tieroddia && formik.errors.tieroddia ? (
              <div className="error">{formik.errors.tieroddia}</div>
            ) : null}
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="tieroddia"> Tie Rod Quantity </label>
            <input
              type="text"
              name="tierodquantity"
              id="tierodquantity"
              className="form-control"
              placeholder="Tie Rod Quantity"
              value={formik.values.tierodquantity}
              onChange={formik.handleChange}
            />
            {formik.touched.tierodquantity && formik.errors.tierodquantity ? (
              <div className="error">{formik.errors.tierodquantity}</div>
            ) : null}
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-20">
            <label htmlFor="tiematerial"> Tie Rod Material </label>
            <Select
              id="tiematerial"
              name="tiematerial"
              options={materialOptions}
              onChange={(option) =>
                formik.setFieldValue("tiematerial", option.value)
              }
              value={
                formik.values.tiematerial
                  ? {
                      value: formik.values.tiematerial,
                      label: formik.values.tiematerial,
                    }
                  : null
              }
              onBlur={formik.handleBlur}
            />
            {formik.touched.tiematerial && formik.errors.tiematerial ? (
              <div className="error">{formik.errors.tiematerial}</div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="w-100 row px-4 col-md-12 col-sm-12">
        <div className="mb-10">
          <label>
            <b>Element Fixtures</b>
          </label>
        </div>
        <div className="d-flex w-100 row">
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="distancebwionozzels">
              Distance Between I/O Nozzels
            </label>
            <input
              name="distancebwionozzels"
              type="text"
              id="distancebwionozzels"
              className="form-control"
              placeholder="Distance Between I/O Nozzels"
              value={formik.values.distancebwionozzels}
              onChange={(e) => {
                e.preventDefault();
                formik.setFieldValue("distancebwionozzels", e.target.value);
              }}
            />
            {formik.touched.distancebwionozzels &&
            formik.errors.distancebwionozzels ? (
              <div className="error">{formik.errors.distancebwionozzels}</div>
            ) : null}
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="fixingofheatingelements">
              Fixing of Heating Elements
            </label>
            <Select
              id="fixingofheatingelements"
              name="fixingofheatingelements"
              options={[
                { value: "Sleeves", label: "Sleeves" },
                { value: "Direct", label: "Direct" },
                { value: "Bush", label: "Bush" },
                { value: "Nut", label: "Nut" },
                { value: "Ferrule", label: "Ferrule" },
                { value: "Casing Pipe", label: "Casing Pipe" },
                { value: "Tig + Sleeves", label: "Tig + Sleeves" },
                { value: "Direct - Braz", label: "Direct - Braz" },
              ]}
              onChange={handleFixingChange}
              value={
                fixingOfHeatingElements
                  ? {
                      value: fixingOfHeatingElements,
                      label: fixingOfHeatingElements,
                    }
                  : null
              }
              onBlur={formik.handleBlur}
            />
            {formik.touched.fixingofheatingelements &&
            formik.errors.fixingofheatingelements ? (
              <div className="error">
                {formik.errors.fixingofheatingelements}
              </div>
            ) : null}
          </div>

          {(fixingOfHeatingElements === "Sleeves" ||
            fixingOfHeatingElements === "Tig + Sleeves" ||
            fixingOfHeatingElements === "Bush") && (
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15 ">
              <label htmlFor="materialofsleevesbush">
                Material of {fixingOfHeatingElements}
              </label>
              <Select
                id="materialofsleevesbush"
                name="materialofsleevesbush"
                options={materialOptions}
                onChange={(option) =>
                  formik.setFieldValue("materialofsleevesbush", option.value)
                }
                value={
                  formik.values.materialofsleevesbush
                    ? {
                        value: formik.values.materialofsleevesbush,
                        label: formik.values.materialofsleevesbush,
                      }
                    : null
                }
                onBlur={formik.handleBlur}
              />
              {formik.touched.materialofsleevesbush &&
              formik.errors.materialofsleevesbush ? (
                <div className="error">
                  {formik.errors.materialofsleevesbush}
                </div>
              ) : null}
            </div>
          )}

          {(fixingOfHeatingElements === "Sleeves" ||
            fixingOfHeatingElements === "Tig + Sleeves" ||
            fixingOfHeatingElements === "Bush") && (
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="sleevethickness">
                {fixingOfHeatingElements} Thickness (mm)
              </label>
              <Select
                id="sleevethickness"
                name="sleevethickness"
                options={[
                  { value: "2", label: "2" },
                  { value: "3", label: "3" },
                  { value: "4", label: "4" },
                  { value: "5", label: "5" },
                  { value: "6", label: "6" },
                ]}
                onChange={(option) =>
                  formik.setFieldValue("sleevethickness", option.value)
                }
                value={
                  formik.values.sleevethickness
                    ? {
                        value: formik.values.sleevethickness,
                        label: formik.values.sleevethickness,
                      }
                    : null
                }
                onBlur={formik.handleBlur}
              />
              {formik.touched.sleevethickness &&
              formik.errors.sleevethickness ? (
                <div className="error">{formik.errors.sleevethickness}</div>
              ) : null}
            </div>
          )}

          {fixingOfHeatingElements === "Nut" && (
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="nutsize">Nut Size</label>
              <input
                type="text"
                className="form-control"
                placeholder="Nut Size"
                name="nutsize"
                value={formik.values.nutsize}
                onChange={(e) => {
                  e.preventDefault();
                  formik.setFieldValue("nutsize", e.target.value);
                }}
                onBlur={formik.handleBlur}
                required
              />
              {formik.touched.nutsize && formik.errors.nutsize ? (
                <div className="error">{formik.errors.nutsize}</div>
              ) : null}
            </div>
          )}
        </div>
        <div className="d-flex w-100 row">
          <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
            <label htmlFor="sheathtemp">Sheath Temp</label>
            <input
              name="sheathtemp"
              type="text"
              id="sheathtemp"
              className="form-control"
              placeholder="Enter sheathtemp"
              value={formik.values.sheathtemp}
              onChange={formik.handleChange}
            />
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
}

export default Screen3;
